import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";

const UpdatePharmacyDetails = ({
  isVisible,
  handleClose,
  selectedPharmacy,
  handleUpdate,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleUpdate({ ...selectedPharmacy, ...values });
    handleClose();
  };

  return (
    <Modal
      title="Update Pharmacy Details"
      open={isVisible}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Update
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={selectedPharmacy}
      >
        <Form.Item
          name="name"
          label="Pharmacy Name"
          rules={[
            { required: true, message: "Please enter the pharmacy name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["address", "district"]}
          label="District"
          rules={[{ required: true, message: "Please enter the district" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["address", "city"]}
          label="City"
          rules={[{ required: true, message: "Please enter the city" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["address", "town"]}
          label="Town"
          rules={[{ required: true, message: "Please enter the town" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="admin_name"
          label="Admin Name"
          rules={[{ required: true, message: "Please enter the admin name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="admin_email"
          label="Admin Email"
          rules={[{ required: true, message: "Please enter the admin email" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePharmacyDetails;

import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Select } from "antd";
import { apiRequest, apiRequestWithImage } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { toast } from "react-toastify";

const { TextArea } = Input;

function UpdateDetails({
  visible,
  onClose,
  drug,
  setOpen,
  refresh,
  setRefresh,
}) {
  const [drugCategories, setDrugCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // State to hold the file

  const getDrugCategories = () => {
    setLoading(true);
    const onSuccess = (response) => {
      if (Array.isArray(response) && Array.isArray(response[0])) {
        setDrugCategories(response[0]);
      }
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}categories`, "", "", onSuccess, onError);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (drug) {
      form.setFieldsValue({
        name: drug.name,
        description: drug.description,
        stock: drug.stock,
        price: drug.price,
        dosage_form: drug.dosage_form,
        manufacturer: drug.manufacturer,
        buying_price: drug.buying_price,
        warnings: drug.warnings,
        side_effects: drug.side_effects,
        category_id: drug.category_id,
      });
    }
  }, [drug, form]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
       const formData = new FormData();

       // Append each field value to FormData
       Object.keys(values).forEach((key) => {
         formData.append(key, values[key]);
       });

       // Append the file if it's selected
       if (file) {
         formData.append("thumbnail", file);
       }
        apiRequestWithImage(
          "post",
          `${API_BASE_URL}medicines/${drug.id}`,
          formData,
          "",
          (response) => {
            toast.success("Drug details updated successfully!", {
              theme: "colored",
            });
            setOpen(false);
            setRefresh(!refresh);
          },
          (error) => {
            console.log(error);
            toast.error("Error Occurred", {
              theme: "colored",
            });
          }
        );
      })
      .catch((info) => {
        toast.error("Validation Error", {
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    getDrugCategories();
  }, []);

  return (
    <Modal
      title="Update Drug Details"
      open={visible}
      onOk={handleOk}
      onCancel={onClose}
      okText="Update"
      cancelText="Cancel"
    >
      <Form
        form={form}
        layout="vertical"
        name="update_drug_form"
        initialValues={{}}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the drug name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="stock"
          label="Stock"
          rules={[
            { required: true, message: "Please input the stock amount!" },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: "Please input the price!" }]}
        >
          <InputNumber min={0} step={0.01} />
        </Form.Item>
        <Form.Item name="dosage_form" label="Dosage Form">
          <Input />
        </Form.Item>
        <Form.Item
          name="manufacturer"
          label="Manufacturer"
          rules={[
            { required: true, message: "Please input the manufacturer!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="buying_price"
          label="Buying Price"
          rules={[
            { required: true, message: "Please input the buying price!" },
          ]}
        >
          <InputNumber min={0} step={0.01} />
        </Form.Item>
        <Form.Item name="warnings" label="Warnings">
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item name="side_effects" label="Side Effects">
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category_id"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select>
            {drugCategories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <label
            htmlFor="thumbnail"
            className="block text-sm text-gray-500 "
          >
            Image
          </label>
          <input
            type="file"
            id="thumbnail"
            name="thumbnail"
            onChange={handleFileChange}
            className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full   placeholder-gray-400/70  focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
          />
        </div>
      </Form>
    </Modal>
  );
}

export default UpdateDetails;

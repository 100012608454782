import React, {useEffect, useState} from 'react'
import Nav from '../nav';
import { toast, ToastContainer } from 'react-toastify';
import { apiRequest } from '../../common/common';
import { API_BASE_URL } from '../../common/constants';
import { FaHospital } from 'react-icons/fa';
import Swal from 'sweetalert2';
import UpdatePharmacyDetails from './UpdatePharmacyDetails';

function ViewPharmacy() {
  const [pharmacies, setPharmacies] = useState([])
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedPharmacy, setSelectedPharmacy] = useState(null);

   /**
    * 
    * @param {*} pharmacy 
    * @description function 
    */
    const handleEdit = (pharmacy) => {
      setSelectedPharmacy(pharmacy);
      setIsModalVisible(true);
    };
  const getPharmacies = ()=>{
    setLoading(true)
    const onSuccess =(response)=>{
      setPharmacies(response);
      setLoading(false);
    }
    const onError = (error)=>{
      toast.error("Error Occured", {
        theme:"colored"
      });
      setLoading(false);
    }

    apiRequest(
      "get",
      `${API_BASE_URL}pharmacies`,
      "",
      "",
      onSuccess,
      onError
    )
  }

  /**
   * 
   * @param {*} pharmacy 
   * @description deactivate pharmacy account
   */
  const deActivate = (pharmacy)=>{
     Swal.fire({
       title: "Are you sure?",
       text: `Do you want to deactivate ${pharmacy.name}?`,
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#d33",
       cancelButtonColor: "#3085d6",
       confirmButtonText: "Yes, deactivate!",
       cancelButtonText: "No, cancel!",
     }).then((result) => {
       if (result.isConfirmed) {
         setLoading(true);

         const onSuccess = (response) => {
           setLoading(false);
           Swal.fire({
             icon: "success",
             title: "Deactivation Successful",
             text: `${pharmacy.name} has been deactivated.`,
           });
           getPharmacies();
         };

         const onError = (error) => {
           setLoading(false);
           Swal.fire({
             icon: "error",
             title: "Error Occurred",
             text: "An error occurred during deactivation.",
           });
         };

         apiRequest(
           "patch",
           `${API_BASE_URL}pharmacies/${pharmacy.id}/deactivate`,
           "",
           "",
           onSuccess,
           onError
         );
       }
     });
  }
  /**
   * 
   * @param {*} updatedPharmacy 
   * @description update pharmacy details
   */
   const handleUpdate = (updatedPharmacy) => {
  
     const onSuccess = (response)=>{
      toast.success("Update Successful", {
        theme:"colored"
      })
      getPharmacies();
     }
     const onError = (error) => {
      toast.error("Error Occurred")
     }
     apiRequest(
      "put",
      `${API_BASE_URL}pharmacies/${selectedPharmacy.id}`,
      updatedPharmacy,
      "",
      onSuccess,
      onError
     )
   };

  useEffect(() => {   
    getPharmacies();
  }, []);
  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <div className="flex-1 p-8 bg-gray-100 ">
          <section className="container px-4 mx-auto">
            <div className="flex items-center gap-x-3">
              <h1 className="text-lg font-medium text-gray-800 ">
                Pharmacies
              </h1>

              <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full  ">
                {pharmacies.length} pharmacies
              </span>
            </div>

            <div className="flex flex-col mt-6">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 ">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded   "
                              />
                              <span>Name</span>
                            </div>
                          </th>
                          <th className="px-12 py-3.5 text-sm font-normal text-left text-gray-500 ">
                            Pharmacy Phone
                          </th>
                          <th className="px-12 py-3.5 text-sm font-normal text-left text-gray-500 ">
                            Status
                          </th>
                          <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                            Admin Name
                          </th>
                          <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                            Admin Email
                          </th>
                          <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                            Address
                          </th>
                          <th className="relative py-3.5 px-4 font-normal text-left text-gray-500 ">
                            <span className="sr-only">Edit</span>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200  ">
                        {loading ? (
                          Array.from({ length: 5 }).map((_, index) => (
                            <tr key={index} className="animate-pulse">
                              <td className="px-4 py-4">
                                <div className="h-6 bg-gray-300  rounded"></div>
                              </td>
                              <td className="px-12 py-4">
                                <div className="h-6 bg-gray-300  rounded"></div>
                              </td>
                              <td className="px-4 py-4">
                                <div className="h-6 bg-gray-300  rounded"></div>
                              </td>
                              <td className="px-4 py-4">
                                <div className="h-6 bg-gray-300  rounded"></div>
                              </td>
                              <td className="px-4 py-4">
                                <div className="h-6 bg-gray-300  rounded"></div>
                              </td>
                            </tr>
                          ))
                        ) : pharmacies ? (
                          pharmacies.map((pharmacy) => (
                            <tr key={pharmacy.id}>
                              <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <div className="inline-flex items-center gap-x-3">
                                  <input
                                    type="checkbox"
                                    className="text-blue-500 border-gray-300 rounded   "
                                  />
                                  <div className="flex items-center gap-x-2">
                                    <FaHospital className="w-6 h-10 rounded-full" />
                                    <div>
                                      <h2 className="font-medium text-gray-800 ">
                                        {pharmacy.name}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                {" "}
                                {pharmacy.contacts.map((contact, index) => (
                                  <span
                                    className="border border-blue-500 rounded-md text-blue-500 py-1 px-1"
                                     key={index}
                                  >
                                    {contact}
                                    {index < pharmacy.contacts.length - 1}
                                  </span>
                                ))}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <div
                                  className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                                    pharmacy.status === "active"
                                      ? "bg-emerald-100/60"
                                      : "bg-red-100/60"
                                  } `}
                                >
                                  <h2
                                    className={`text-sm font-normal ${
                                      pharmacy.status === "active"
                                        ? " text-emerald-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {pharmacy.status}
                                  </h2>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                                {pharmacy.admin_name}
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                                {pharmacy.admin_email}
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <p className="px-3 py-1 text-xs text-indigo-500 rounded-full  bg-indigo-100/60">
                                    {pharmacy.address.district}
                                  </p>
                                  <p className="px-3 py-1 text-xs text-blue-500 rounded-full  bg-blue-100/60">
                                    {pharmacy.address.city}
                                  </p>
                                  <p className="px-3 py-1 text-xs text-pink-500 rounded-full  bg-pink-100/60">
                                    {pharmacy.address.town}
                                  </p>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex items-center gap-x-6">
                                  <button
                                    onClick={() => deActivate(pharmacy)}
                                    className="text-gray-500 transition-colors duration-200  hover:text-red-500 focus:outline-none"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      color="#000000"
                                      fill="none"
                                    >
                                      <path
                                        d="M19.5 5.5L19.0982 12.0062M4.5 5.5L5.10461 15.5248C5.25945 18.0922 5.33688 19.3759 5.97868 20.299C6.296 20.7554 6.7048 21.1407 7.17905 21.4302C7.85035 21.84 8.68108 21.9631 10 22"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                      />
                                      <path
                                        d="M20 15L13 21.9995M20 22L13 15.0005"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </button>

                                  <button
                                    className="text-gray-500 transition-colors duration-200  hover:text-blue-500 focus:outline-none"
                                    onClick={() => handleEdit(pharmacy)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      color="#000000"
                                      fill="none"
                                    >
                                      <path
                                        d="M14.0737 3.88545C14.8189 3.07808 15.1915 2.6744 15.5874 2.43893C16.5427 1.87076 17.7191 1.85309 18.6904 2.39232C19.0929 2.6158 19.4769 3.00812 20.245 3.79276C21.0131 4.5774 21.3972 4.96972 21.6159 5.38093C22.1438 6.37312 22.1265 7.57479 21.5703 8.5507C21.3398 8.95516 20.9446 9.33578 20.1543 10.097L10.7506 19.1543C9.25288 20.5969 8.504 21.3182 7.56806 21.6837C6.63212 22.0493 5.6032 22.0224 3.54536 21.9686L3.26538 21.9613C2.63891 21.9449 2.32567 21.9367 2.14359 21.73C1.9615 21.5234 1.98636 21.2043 2.03608 20.5662L2.06308 20.2197C2.20301 18.4235 2.27297 17.5255 2.62371 16.7182C2.97444 15.9109 3.57944 15.2555 4.78943 13.9445L14.0737 3.88545Z"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13 4L20 11"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14 22L22 22"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="font-bold text-2xl flex justify-content-center">
                            No Pharmacies Found
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </section>
        </div>
      </div>
      <UpdatePharmacyDetails
        isVisible={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
        selectedPharmacy={selectedPharmacy}
        handleUpdate={handleUpdate}
      />
    </div>
  );
}

export default ViewPharmacy
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "../common/common";
import { API_BASE_URL } from "../common/constants";
import Nav from "./nav";
import DashboardAdmin from "./dashboard/DasboardAdmin";
import DashboardHospAdmin from "./dashboard/DashboardHospAdmin";
import DashboardDoctor from "./dashboard/DashboardDoctor";
import { useSelector } from "react-redux";
import PharmacyDashboard from "./dashboard/PharmacyDashboard";

function Analytics() {
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const { user } = useSelector((state) => state.auth.user);

  const getAnalytics = () => {
    setLoading(true);
    const onSuccess = (response) => {
      toast.success("Analytics Loaded", { theme: "colored" });
      setAnalytics(response);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred", { theme: "colored" });
      setLoading(false);
    };

    const url =
      dayOfWeek != null
        ? `${API_BASE_URL}analytics?day_of_week=${dayOfWeek}`
        : `${API_BASE_URL}analytics/`;

    apiRequest("get", url, "", "", onSuccess, onError);
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  useEffect(() => {
    getAnalytics();
  }, [dayOfWeek]);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <div className="min-h-screen bg-gray-100">
          {loading ? (
            <div className="flex flex-col items-center justify-center min-h-screen">
              <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
              <p className="mt-4 text-lg font-medium text-gray-600 ">
                Loading analytics...
              </p>
            </div>
          ) : (
            <>
              {user?.role_id === 1 && <DashboardAdmin stats={analytics} />}
              {user?.role_id === 2 && <DashboardHospAdmin data={analytics} />}
              {user?.role_id === 3 && <DashboardDoctor data={analytics} />}
              {user?.role_id === 4 && (
                <PharmacyDashboard
                  data={analytics}
                  onDayOfWeekChange={setDayOfWeek}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Analytics;

import React, { useState, useEffect } from "react";
import { Modal, Button, Spin, Select } from "antd"; // Import Select from Ant Design
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function AssignDoctor({
  inquiry,
  setOpen,
  open,
  refresh,
  setRefresh,
  setExpandedRow,
}) {
  const [userHospital, setUserHospital] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to get user hospital details
  const getUserHospital = () => {
    const onSuccess = (response) => {
      setUserHospital(response.data);
    };
    const onError = (error) => {
      toast.error("Error occurred while fetching hospital details.");
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  // Function to fetch doctors
  const fetchDoctors = () => {
    setLoading(true);
    const onSuccess = (response) => {
      
      setDoctors(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      setLoading(false);
      toast.error("Error occurred while fetching doctors.");
    };
    const params = {
      hospital_id: userHospital?.hospital?.id,
    };
    apiRequest("get", `${API_BASE_URL}doctors`, "", params, onSuccess, onError);
  };

  // Function to handle doctor assignment
  const handleAssignDoctor = () => {
    if (!selectedDoctor) {
      toast.error("Please select a doctor.");
      return;
    }

    const onSuccess = () => {
      toast.success("Doctor assigned successfully!", {
        theme: "colored",
      });
      setOpen(false);
      setExpandedRow(null);
      setRefresh(!refresh);
    };
    const onError = (error) => {
      toast.error("Error occurred while assigning doctor.", {
        theme: "colored",
      });
    };

    const params = {
      assigned_doctor_id: selectedDoctor,
    };

    apiRequest(
      "post",
      `${API_BASE_URL}patients/${inquiry?.id}/assign-doctor`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getUserHospital();
  }, []);

  useEffect(() => {
    if (userHospital?.hospital?.id) {
      fetchDoctors();
    }
  }, [userHospital]);

  // Function to handle modal close
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title="Assign Doctor"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAssignDoctor}
          >
            {loading ? "Assigning..." : "Assign"}
          </Button>,
        ]}
      >
        {loading ? (
          <Spin />
        ) : (
          <div>
            <label htmlFor="doctorSelect" className="block text-gray-700 mb-2">
              Select Doctor:
            </label>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a doctor"
              optionFilterProp="children"
              value={selectedDoctor}
              onChange={(value) => setSelectedDoctor(value)}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
            >
              {doctors.map((doctor) => (
                <Select.Option key={doctor.id} value={doctor.id}>
                  {doctor.name} 
                  {doctor.specializations.map((spec, key) => (
                    <span className="text-blue-500 border rounded-md m-1 px-1 border-blue-500" key={key}>{spec.name}</span>
                  ))}
                  
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </Modal>
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default AssignDoctor;

import React from "react";

const StatisticCard = ({ title, value, icon, bgColor }) => {
  return (
    <div
      className={`bg-gradient-to-r ${bgColor} shadow-lg rounded-lg p-6 flex flex-col justify-center items-center text-center text-white`}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-2xl font-bold mt-2">{value}</p>
    </div>
  );
};

export default StatisticCard;

import React from "react";

const StatisticCard = ({ title, value, gradientFrom, gradientTo, icon }) => {
  return (
    <div
      className={`p-6 rounded-lg shadow-lg text-white ${gradientFrom} ${gradientTo} bg-gradient-to-r`}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
};

export default StatisticCard;

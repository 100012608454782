import React, { useState, useEffect } from "react";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { toast, ToastContainer } from "react-toastify";
import Nav from "../nav";
import { Button, Input, Modal } from "antd";

const { TextArea } = Input;

function DoctorPatientInquiries() {
  const [patientInquiries, setPatientInquiries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [openModal , setOpenModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null)
  const [formData, setFormData] = useState({
    prescription:"",
    summary:"",
    reason:""
  })


 const handleInputChange = (e) => {
   const { name, value } = e.target;
   setFormData((prevFormData) => ({
     ...prevFormData,
     [name]: value,
   }));
 };
  const fetchDoctorPatientInquiries = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setPatientInquiries(response.inquiries);
      setLoading(false);
    };
    const onError = () => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}doctors/inquiries`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const renderJsonDetails = (json) => {
    if (!json) return "None";
    try {
      const obj = JSON.parse(json);
      return Object.entries(obj).map(([key, value]) => (
        <p key={key} className="text-gray-700 ">
          <span className="font-semibold">{key}:</span> {value}
        </p>
      ));
    } catch (e) {
      return "Invalid data";
    }
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "submitted for review":
        return "bg-blue-100 text-blue-800";
      case "doctor responding":
        return "bg-cyan-100 text-cyan-800";
      case "completed":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleModal = (inquiry) => {
    setOpenModal(true);
    setSelectedInquiry(inquiry);
  }

  const handleReasonModal = (inquiry) =>{
    setReasonModal(true);
    setSelectedInquiry(inquiry);
  }

  const updateStatus = (inquiry, status)=>{
    setLoading(true)
    const onSuccess = (response) =>{
        toast.success("Status Updated",{
            theme:"colored"
        });
        setLoading(false)
        fetchDoctorPatientInquiries();
        setExpandedRow(null);
        setFormData({
          prescription: "",
          summary: "",
        });
        setOpenModal(false);
    }
    const onError =(response)=>{
        toast.error('Error Occured', {
            theme:"colored"
        })
        setLoading(false)
    }

    const data ={
        status_id:status,
        prescription: formData.prescription,
        summary:formData.summary
    }

    apiRequest("patch", `${API_BASE_URL}doctors/inquiries/${inquiry?.id}/status`, data, "",onSuccess, onError);
  }
const reject = (inquiry) => {
  setLoading(true);
  const onSuccess = (response) => {
    toast.success("Status Updated", {
      theme: "colored",
    });
    setLoading(false);
    fetchDoctorPatientInquiries();
    setExpandedRow(null);
    setFormData({
      prescription: "",
      summary: "",
      reason:""
    });
    setReasonModal(false)
  };
  const onError = (response) => {
    toast.error("Error Occured", {
      theme: "colored",
    });
    setLoading(false);
  };

  const data = {
    reason: formData.reason,
    
  };

  apiRequest(
    "patch",
    `${API_BASE_URL}doctors/inquiries/${inquiry?.id}/reject`,
    data,
    "",
    onSuccess,
    onError
  );
};
  useEffect(() => {
    fetchDoctorPatientInquiries();
  }, []);
  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <div className="overflow-x-auto rounded-lg shadow-md">
          <table className="min-w-full divide-y divide-gray-200  bg-white ">
            <thead className="bg-gray-50 ">
              <tr>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Hospital Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Patient Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Patient Address
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Inquiry Status
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Specialization
                </th>
              </tr>
            </thead>
            <tbody className="bg-white  divide-y divide-gray-200 ">
              {patientInquiries ? (
                patientInquiries.map((inquiry, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() => handleRowClick(index)}
                      className={`cursor-pointer hover:bg-gray-100  ${
                        index % 2 === 0
                          ? "bg-gray-50 "
                          : "bg-white "
                      }`}
                    >
                      <td className="py-4 px-6 text-sm font-medium text-gray-900 ">
                        {inquiry.hospital.hosp_name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.patient.name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.patient.address}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusClass(
                            inquiry.inquiry_status.name
                          )}`}
                        >
                          {inquiry.inquiry_status.name}
                        </span>
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.specialization
                          ? inquiry.specialization.name
                          : "N/A"}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td
                          colSpan="5"
                          className="p-4 bg-gray-50 "
                        >
                          <div className="p-4 bg-white  rounded-lg shadow-md">
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Allergies:
                            </p>
                            <div className="ml-4 mb-4">
                              {renderJsonDetails(inquiry.patient.allergies)}
                            </div>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Medical History:
                            </p>
                            <div className="ml-4 mb-4">
                              {renderJsonDetails(
                                inquiry.patient.medical_history
                              )}
                            </div>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Gender:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.patient.gender || "Unknown"}
                            </p>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Contact:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.patient.contact || "Unknown"}
                            </p>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Inquiry Description:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.inquiry_description || "N/A"}
                            </p>

                            <div className=" float-end font-extralight">
                              {inquiry.status_id === 2 && (
                                <>
                                  <button
                                    onClick={() => handleReasonModal(inquiry)}
                                    disabled={loading}
                                    className="py-2 px-4 rounded-md border hover:border-red-500 hover:bg-white hover:text-red-500 bg-red-500 text-white"
                                  >
                                    {loading ? "Rejecting..." : "Reject"}
                                  </button>
                                  <button
                                    onClick={() => updateStatus(inquiry, 3)}
                                    disabled={loading}
                                    className="py-2 px-4 rounded-md border hover:border-green-500 hover:bg-white hover:text-green-500 bg-green-500 text-white"
                                  >
                                    {loading ? "Accepting..." : "Accept"}
                                  </button>
                                </>
                              )}
                              {inquiry.status_id === 3 && (
                                <button
                                  onClick={() => handleModal(inquiry)}
                                  disabled={loading}
                                  className="py-2 px-4 rounded-md border hover:border-green-500 hover:bg-white hover:text-green-500 bg-green-500 text-white"
                                >
                                  {loading ? "Completing..." : "Complete"}
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div className="font">No Patient Inquiries Found</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => updateStatus(selectedInquiry, 4)}
          >
            Complete
          </Button>,
        ]}
      >
        <label>Summary</label>
        <TextArea
          rows={4}
          placeholder="Summary"
          name="summary"
          value={formData.summary}
          onChange={handleInputChange}
        />
        <label>Prescription (if Any)</label>
        <TextArea
          rows={4}
          placeholder="Prescription"
          name="prescription"
          value={formData.prescription}
          onChange={handleInputChange}
        />
      </Modal>
      <Modal
        open={reasonModal}
        onCancel={() => setReasonModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => reject(selectedInquiry)}
          >
            Submit
          </Button>,
        ]}
      >
        <label>Reason for rejecting</label>
        <TextArea
          rows={4}
          placeholder="Give Reason for rejecting"
          name="reason"
          value={formData.reason}
          onChange={handleInputChange}
        />
      </Modal>
    </div>
  );
}

export default DoctorPatientInquiries;

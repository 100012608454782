import React, { useState } from "react";
import Nav from "../../nav";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";

function RegisterHospital() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    hosp_name: "",
    hosp_address: {
      district: "",
      city: "",
      town: "",
    },
    latitude: "",
    longitude: "",
    hosp_contacts: [""],
    hosp_admin: "",
    hosp_admin_contacts: [""],
    hosp_admin_email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.hosp_name || formData.hosp_name.length > 255) {
      newErrors.hosp_name =
        "Hospital name is required and must be at most 255 characters long.";
    }

    if (
      !formData.hosp_address.city ||
      formData.hosp_address.city.length > 255
    ) {
      newErrors.hosp_address_city =
        "City is required and must be at most 255 characters long.";
    }

    if (formData.hosp_address.town && formData.hosp_address.town.length > 255) {
      newErrors.hosp_address_town = "Town must be at most 255 characters long.";
    }

    // if (
    //   formData.latitude === "" ||
    //   isNaN(formData.latitude) ||
    //   formData.latitude < -90 ||
    //   formData.latitude > 90
    // ) {
    //   newErrors.latitude =
    //     "Latitude is required and must be between -90 and 90.";
    // }

    // if (
    //   formData.longitude === "" ||
    //   isNaN(formData.longitude) ||
    //   formData.longitude < -180 ||
    //   formData.longitude > 180
    // ) {
    //   newErrors.longitude =
    //     "Longitude is required and must be between -180 and 180.";
    // }

    if (
      !Array.isArray(formData.hosp_contacts) ||
      formData.hosp_contacts.length === 0 ||
      formData.hosp_contacts.some((contact) => !contact || contact.length > 15)
    ) {
      newErrors.hosp_contacts =
        "At least one contact is required, and each contact must be at most 15 characters long.";
    }

    if (!formData.hosp_admin || formData.hosp_admin.length > 255) {
      newErrors.hosp_admin =
        "Admin name is required and must be at most 255 characters long.";
    }

    if (
      !Array.isArray(formData.hosp_admin_contacts) ||
      formData.hosp_admin_contacts.length === 0 ||
      formData.hosp_admin_contacts.some(
        (contact) => !contact || contact.length > 15
      )
    ) {
      newErrors.hosp_admin_contacts =
        "At least one admin contact is required, and each contact must be at most 15 characters long.";
    }

    if (
      !formData.hosp_admin_email ||
      !/\S+@\S+\.\S+/.test(formData.hosp_admin_email) ||
      formData.hosp_admin_email.length > 255
    ) {
      newErrors.hosp_admin_email =
        "A valid email address is required and must be at most 255 characters long.";
    }

    if (!formData.password || formData.password.length < 6) {
      newErrors.password =
        "Password is required and must be at least 6 characters long.";
    }

    if (formData.password !== formData.password_confirmation) {
      newErrors.password_confirmation = "Passwords must match.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const onSuccess = () => {
      toast.success("Hospital Record Registered", { theme: "colored" });
      setFormData({
        hosp_name: "",
        hosp_address: {
          district: "",
          city: "",
          town: "",
        },
        latitude: "",
        longitude: "",
        hosp_contacts: [""],
        hosp_admin: "",
        hosp_admin_contacts: [""],
        hosp_admin_email: "",
        password: "",
        password_confirmation: "",
      });
      setLoading(false)
    };

    const onError = (error) => {
      toast.error(error?.response?.data.error || "Error Occurred", {
        theme: "colored",
      });
      setLoading(false);

    };

    apiRequest(
      "post",
      `${API_BASE_URL}admin/register-hospital`,
      formData,
      "",
      onSuccess,
      onError
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      hosp_address: { ...prevData.hosp_address, [name]: value },
    }));
  };

  const handleArrayChange = (e, index, arrayName) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const updatedArray = [...prevData[arrayName]];
      updatedArray[index] = value;
      return { ...prevData, [arrayName]: updatedArray };
    });
  };

  const addArrayField = (arrayName) => {
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: [...prevData[arrayName], ""],
    }));
  };

  const removeArrayField = (index, arrayName) => {
    setFormData((prevData) => {
      const updatedArray = prevData[arrayName].filter((_, i) => i !== index);
      return { ...prevData, [arrayName]: updatedArray };
    });
  };

  const handleGeoLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude.toFixed(6),
            longitude: position.coords.longitude.toFixed(6),
          }));
          toast.success("Location captured successfully!", {
            theme: "colored",
          });
        },
        (error) => {
          toast.error("Failed to get location. Please try again.", {
            theme: "colored",
          });
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.", {
        theme: "colored",
      });
    }
  };

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Register New Hospital
          </h2>

          <form onSubmit={handleSubmit}>
            {/* Step 1: Hospital Information */}
            {currentStep === 1 && (
              <div className="mt-4">
                <div className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold text-lg rounded-full shadow-sm">
                  Hospital Information
                </div>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  {/* Hospital Name */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="hosp_name"
                    >
                      Hospital Name
                    </label>
                    <input
                      name="hosp_name"
                      value={formData.hosp_name}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                    />
                    {errors.hosp_name && (
                      <p className="text-red-500">{errors.hosp_name}</p>
                    )}
                  </div>

                  {/* Hospital Address */}
                  <div>
                    <label className="text-gray-700 ">
                      Address
                    </label>
                    <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2">
                      {["district", "city", "town"].map((field) => (
                        <input
                          key={field}
                          name={field}
                          placeholder={
                            field.charAt(0).toUpperCase() + field.slice(1)
                          }
                          value={formData.hosp_address[field]}
                          onChange={handleAddressChange}
                          type="text"
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                        />
                      ))}
                    </div>
                  </div>

                  {/* Latitude */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="latitude"
                    >
                      Latitude
                    </label>
                    <input
                      name="latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      readOnly
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.latitude && (
                      <p className="text-red-500">{errors.latitude}</p>
                    )}
                  </div>

                  {/* Longitude */}
                  <div className="grid grid-cols-2 items-center gap-4">
                    {/* Longitude Label and Input Field */}
                    <div>
                      <label
                        className="text-gray-700  mr-2"
                        htmlFor="longitude"
                      >
                        Longitude
                      </label>
                      <input
                        name="longitude"
                        value={formData.longitude}
                        onChange={handleChange}
                        readOnly
                        type="text"
                        className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                      />
                      {errors.longitude && (
                        <p className="text-red-500">{errors.longitude}</p>
                      )}
                    </div>

                    {/* Get Current Location Button */}
                    <div className="flex mt-6 justify-evenly">
                      <button
                        onClick={handleGeoLocation}
                        className="border border-red-500 px-1 py-2 rounded-md text-red-500 bg-white hover:bg-red-500 hover:text-white"
                      >
                        Get Current Location
                      </button>
                    </div>
                  </div>

                  {/* Hospital Contacts */}
                  <div>
                    <label className="text-gray-700 ">
                      Hospital Phone Contact
                    </label>
                    {formData.hosp_contacts.map((contact, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          value={contact}
                          onChange={(e) =>
                            handleArrayChange(e, index, "hosp_contacts")
                          }
                          type="text"
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeArrayField(index, "hosp_contacts")
                          }
                          className="text-red-500"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addArrayField("hosp_contacts")}
                      className="mt-2 px-3 py-2 bg-white text-red-400 border border-red-400 rounded-md hover:bg-red-500 font-thin hover:text-white"
                    >
                      Add More Phone Contacts
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Step 2: Admin Details & Password */}
            {currentStep === 2 && (
              <div className="mt-4">
                <div className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold text-lg rounded-full ">
                  Admin Details & Password
                </div>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  {/* Admin Name */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="hosp_admin"
                    >
                      Admin Name
                    </label>
                    <input
                      name="hosp_admin"
                      value={formData.hosp_admin}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.hosp_admin && (
                      <p className="text-red-500">{errors.hosp_admin}</p>
                    )}
                  </div>

                  {/* Admin Email */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="hosp_admin_email"
                    >
                      Admin Email
                    </label>
                    <input
                      name="hosp_admin_email"
                      value={formData.hosp_admin_email}
                      onChange={handleChange}
                      type="email"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.hosp_admin_email && (
                      <p className="text-red-500">{errors.hosp_admin_email}</p>
                    )}
                  </div>

                  {/* Admin Contacts */}
                  <div>
                    <label className="text-gray-700 ">
                      Admin Phone Contacts
                    </label>
                    {formData.hosp_admin_contacts.map((contact, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          value={contact}
                          onChange={(e) =>
                            handleArrayChange(e, index, "hosp_admin_contacts")
                          }
                          type="text"
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeArrayField(index, "hosp_admin_contacts")
                          }
                          className="text-red-500"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addArrayField("hosp_admin_contacts")}
                      className="mt-2 px-3 py-2 bg-white text-red-400 border border-red-400 rounded-md hover:bg-red-500 font-thin hover:text-white"
                    >
                      Add More Phone Contacts
                    </button>
                  </div>

                  {/* Password */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      type="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password}</p>
                    )}
                  </div>

                  {/* Confirm Password */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="password_confirmation"
                    >
                      Confirm Password
                    </label>
                    <input
                      name="password_confirmation"
                      value={formData.password_confirmation}
                      onChange={handleChange}
                      type="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.password_confirmation && (
                      <p className="text-red-500">
                        {errors.password_confirmation}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-end mt-4">
              {currentStep === 2 && (
                <>
                  <button
                    type="button"
                    onClick={() => setCurrentStep(currentStep - 1)}
                    className="px-4 py-2  mr-3 bg-red-500  border text-white border-red-500 rounded-md hover:bg-red-400"
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="px-4 py-2 bg-blue-500 text-white border border-blue-500 rounded-md hover:bg-blue-600"
                  >
                   {loading ? 'Submitting ...' : 'Submit'}
                  </button>
                </>
              )}
              {currentStep === 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  className="px-4 py-2 bg-blue-500 text-white border border-blue-500 rounded-md hover:bg-blue-600"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </section>
      </div>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default RegisterHospital;

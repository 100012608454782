import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { fetchUserRoles } from "../../redux/slice/rolesSlice";
import { loginUser } from "../../redux/slice/authSlice";
import { fetchUserHospital } from "../../redux/slice/userHospitalSlice";
import logo from "../../assets/img/logo.png"

function Login() {
 
 const history = useHistory(); 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const resultAction = await dispatch(loginUser(formData));
      if (loginUser.fulfilled.match(resultAction)) {
        toast.success("Login Successful", {
          theme: "colored",
        });
        localStorage.setItem("token", resultAction.payload.token);

        // Dispatch the fetchUserRoles action
        await dispatch(fetchUserRoles());
        // await dispatch(fetchUserHospital());

          history.push("/analytics");

        
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message, {
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 ">
      <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md ">
        <div className="px-6 py-4">
          <div className="flex justify-center mx-auto">
            <img
              className="w-auto h-96 sm:h-8"
              src={logo}
              alt="Dokitar Hospital Logo"
            />
          </div>
          <h2 className="text-center">Dokitar Hospital</h2>
          <h3 className="mt-3 text-xl font-medium text-center text-gray-600 ">
            Welcome Back
          </h3>
          <p className="mt-1 text-center text-gray-500 ">
            Log into your account
          </p>
          <form onSubmit={handleSubmit}>
            <div className="w-full mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg   focus:border-blue-400  focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                type="email"
                name="email"
                placeholder="Email Address"
                aria-label="Email Address"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-full mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg   focus:border-blue-400  focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                type="password"
                name="password"
                placeholder="Password"
                aria-label="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center justify-center mt-4">
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
            </div>
            <div className="text-red-500 py-3 px-3 w-60"><Link to={"/passwordReset"}><span>Reset Password</span></Link></div>
          </form>
        </div>
      </div>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default Login;

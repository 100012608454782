import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

// Register required elements
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const ChartComponent = ({ data, type }) => {
  const chartData = {
    labels: data.map((d) => d.status_name || d.month_name),
    datasets: [
      {
        label: "No. of Inquiries",
        data: data.map((d) => d.total),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw} inquiries`;
          },
        },
      },
    },
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-full h-80">
      {type === "pie" && <Pie data={chartData} options={options} />}
      {type === "bar" && <Bar data={chartData} options={options} />}
    </div>
  );
};

export default ChartComponent;

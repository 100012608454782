import React from "react";
import StatisticCard from "./doctor/StatisticCard";
import ChartComponent from "./doctor/ChartComponent";
import { IoMdTimer } from "react-icons/io";
import { MdOutlineAssignment, MdChecklist } from "react-icons/md";

const Dashboard = ({ data = {} }) => {
  const {
    total_assigned_inquiries = 0,
    inquiry_statuses = { submitted_for_review: 0, doctor_responding: 0, completed: 0 },
    average_response_time = null,
    age_demographics = {
      "under_18": 0,
      "18_24": 0,
      "25_34": 0,
      "35_44": 0,
      "45_54": 0,
      "55_64": 0,
      "65_plus": 0,
    }
  } = data || {};

  const inquiryStatusData = Object.keys(inquiry_statuses).map((status) => ({
    status: status.replace("_", " "),
    total: inquiry_statuses[status],
  }));

  const ageDemographicsData = Object.keys(age_demographics).map((ageGroup) => ({
    ageGroup: ageGroup.replace("_", " "),
    total: age_demographics[ageGroup],
  }));

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-center mb-4">Dashboard</h1>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <StatisticCard
          title="Total Assigned Inquiries"
          value={total_assigned_inquiries}
          gradientFrom="from-green-400"
          gradientTo="to-green-600"
          icon={<MdOutlineAssignment />}
        />
        <StatisticCard
          title="Average Resolving Patient Inquiries"
          value={
            average_response_time
              ? `${parseInt(average_response_time).toFixed(1)} mins`
              : "N/A"
          }
          gradientFrom="from-blue-400"
          gradientTo="to-blue-600"
          icon={<IoMdTimer />}
        />
        <StatisticCard
          title="Completed Inquiries"
          value={inquiry_statuses.completed}
          gradientFrom="from-purple-400"
          gradientTo="to-purple-600"
          icon={<MdChecklist />}
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Inquiry Status Pie Chart */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <span className=" font-semibold">Patient inquires</span>

          <ChartComponent data={inquiryStatusData} type="pie" />
        </div>

        {/* Age Demographics Bar Chart */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <span className=" font-semibold">Patient Demographics(age)</span>
          <ChartComponent data={ageDemographicsData} type="bar" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";

function ImageOverlay({ visible, onClose, imageUrl }) {
  if (!visible) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div className="relative" onClick={onClose}>
        <img
          src={imageUrl}
          alt="Medicine"
          className="max-w-full max-h-full rounded-lg"
          onClick={onClose}
        />
        <button
          className="absolute top-2 right-2 text-white text-lg font-bold"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
}

export default ImageOverlay;

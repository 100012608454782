import React, { useEffect, useState } from "react";
import Nav from "../nav";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { Select } from "antd";
import { FaEye } from "react-icons/fa"; // Import the eye icon

const { Option } = Select;

function ViewMedicineOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [userPharmacy, setUserPharmacy] = useState([]);
  const [expandedOrderIds, setExpandedOrderIds] = useState([]);

  const handleChange = (value) => {
    setSelectedStatus(value);
  };

  const toggleOrderCollapse = (orderId) => {
    setExpandedOrderIds((prevIds) =>
      prevIds.includes(orderId)
        ? prevIds.filter((id) => id !== orderId)
        : [...prevIds, orderId]
    );
  };

  const getUserPharmacy = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserPharmacy(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      console.log(error);
      toast.error("Error Occurred");
      setLoading(false);
    };
    apiRequest("get", `${API_BASE_URL}user/hospital`, "", "", onSuccess, onError);
  };

  const fetchOrders = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setOrders(response);
      setLoading(false);
    };
    const onError = (error) => {
      setLoading(false);
      toast.error("Error Occurred", {
        theme: "colored",
      });
    };
    let params = { status: selectedStatus, pharmacy_id: userPharmacy?.pharmacy?.id };
    apiRequest("get", `${API_BASE_URL}orders`, "", params, onSuccess, onError);
  };

  const handleCompleteOrder = (order) => {
    const onSuccess = (response) => {
      toast.success("Order Completed", {
        theme: "colored",
      });
      fetchOrders();
    };

    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
    };
    apiRequest(
      "post",
      `${API_BASE_URL}orders/${order}/complete`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleReverseOrder = (order) => {
    const onSuccess = (response) => {
      toast.success("Order Reversed", {
        theme: "colored",
      });
      fetchOrders();
    };

    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
    };

    apiRequest(
      "patch",
      `${API_BASE_URL}orders/${order}/reverse`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getUserPharmacy();
  }, []);

  useEffect(() => {
    if (userPharmacy?.pharmacy?.id) {
      fetchOrders();
    }
  }, [selectedStatus, userPharmacy]);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="container px-4 mx-auto">
          <div className="flex items-center gap-x-3 mb-6 justify-between">
            <h2 className="text-lg font-medium text-gray-800 ">
              Orders{" "}
              <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full  ">
                {orders.length} orders
              </span>
            </h2>

            <div className="flex justify-between">
              <span className="font-bold p-2">Filter By Status </span>
              <Select
                defaultValue={selectedStatus}
                onChange={handleChange}
                className="w-32"
              >
                <Option value={"pending"}>Pending</Option>
                <Option value={"completed"}>Completed</Option>
                <Option value={"reversed"}>Reversed</Option>
              </Select>
            </div>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {orders.length > 0 ? (
                orders.map((order) => {
                  const orderTotal = order.items.reduce(
                    (total, item) => total + parseFloat(item.total_price),
                    0
                  );

                  return (
                    <table className="min-w-full text-sm text-left text-gray-600  bg-white shadow-lg rounded-lg overflow-hidden">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-100  ">
                        <tr>
                          <th scope="col" className="px-2 py-3 text-left">
                            Order No
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Customer
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Status
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Posted At
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Total
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 ">
                        {orders.map((order) => (
                          <React.Fragment key={order.id}>
                            {/* Main Row */}
                            <tr
                              className="cursor-pointer border-b hover:bg-gray-100  transition-all duration-300"
                              onClick={() => toggleOrderCollapse(order.id)}
                            >
                              <td className="px-2 py-4 font-medium text-gray-800  whitespace-nowrap">
                                #{order.id}
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                {order?.user.name}
                              </td>
                              <td className="px-4 py-4">
                                <span
                                  className={`px-3 py-1 rounded-full text-sm font-semibold ${
                                    order.status === "pending"
                                      ? "bg-yellow-100 text-yellow-800"
                                      : order.status === "completed"
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }`}
                                >
                                  {order.status}
                                </span>
                              </td>
                              <td className="px-4 py-4 whitespace-nowrap">
                                {new Date(order.created_at).toLocaleString()}
                              </td>
                              <td className="px-4 py-4 font-bold text-gray-800 ">
                                UGX{" "}
                                {parseInt(
                                  orderTotal.toFixed(0)
                                ).toLocaleString()}
                              </td>
                              <td>
                                <button
                                  className="border border-emerald-500 text-emerald-500 px-2 py-2 m-2 rounded-md hover:bg-emerald-500 hover:text-white"
                                  onClick={() => handleCompleteOrder(order.id)}
                                >
                                  Complete
                                </button>
                                <button
                                  className="border border-rose-500 text-rose-500 px-2 py-2 m-2 rounded-md hover:bg-rose-500 hover:text-white"
                                  onClick={() => handleReverseOrder(order.id)}
                                >
                                  Reverse
                                </button>
                              </td>
                            </tr>

                            {/* Collapsible Row for Order Items */}
                            {expandedOrderIds.includes(order.id) && (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="bg-gray-50  px-4 py-4"
                                >
                                  <div className="overflow-x-auto">
                                    <table className="min-w-full text-sm text-left">
                                      <thead className="text-xs bg-gray-200  ">
                                        <tr>
                                          <th className="px-2 py-2">No</th>
                                          <th className="px-4 py-2">
                                            Medicine
                                          </th>
                                          <th className="px-4 py-2">
                                            Quantity
                                          </th>
                                          <th className="px-4 py-2">
                                            Total Price
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200 ">
                                        {order.items.map((item) => (
                                          <tr key={item.id}>
                                            <td className="px-2 py-2 font-medium text-gray-800 ">
                                              {item.id}
                                            </td>
                                            <td className="px-4 py-2 font-medium text-gray-800 ">
                                              {item.medicine.name}
                                            </td>
                                            <td className="px-4 py-2">
                                              {item.quantity}
                                            </td>
                                            <td className="px-4 py-2 font-bold text-gray-800">
                                              UGX{" "}
                                              {parseInt(
                                                item.total_price
                                              ).toLocaleString()}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  );
                })
              ) : (
                <div className="text-3xl mx-auto">No Active Orders</div>
              )}
            </div>
          )}
        </section>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ViewMedicineOrders;

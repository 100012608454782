import React, { useState } from "react";

const PharmacyForm = () => {
  // Initial form state
  const [formData, setFormData] = useState({
    items: [{ medicine_id: 2, quantity: 80 }],
    pharmacy_id: 1,
  });

  // Handle input change for pharmacy_id
  const handlePharmacyChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseInt(value), // convert to number
    }));
  };

  // Handle input change for medicine items (medicine_id and quantity)
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...formData.items];
    updatedItems[index][name] = parseInt(value); // convert to number
    setFormData((prev) => ({
      ...prev,
      items: updatedItems,
    }));
  };
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjgwMDAvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MjYxMzQ4MjQsImV4cCI6MTcyNjE3MDgyNCwibmJmIjoxNzI2MTM0ODI0LCJqdGkiOiJ5Wm1yRFN4aVExUzBoT0d2Iiwic3ViIjoiNCIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.fIH7C4LkodYmiQ36JXF3osbYEJ9GBAA-PPxM0m9jQlU";

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://127.0.0.1:8000/api/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:`Bearer ${token}`
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log("Form submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-lg"
    >
      <h2 className="text-2xl font-bold mb-6">Pharmacy Order Form</h2>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Pharmacy ID:</label>
        <input
          type="number"
          name="pharmacy_id"
          value={formData.pharmacy_id}
          onChange={handlePharmacyChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      {formData.items.map((item, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Medicine {index + 1}</h3>
          
          <div className="mb-2">
            <label className="block text-gray-700 font-bold mb-2">Medicine ID:</label>
            <input
              type="number"
              name="medicine_id"
              value={item.medicine_id}
              onChange={(e) => handleItemChange(index, e)}
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-2">
            <label className="block text-gray-700 font-bold mb-2">Quantity:</label>
            <input
              type="number"
              name="quantity"
              value={item.quantity}
              onChange={(e) => handleItemChange(index, e)}
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
        </div>
      ))}

      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
      >
        Submit Order
      </button>
    </form>
  );
};

export default PharmacyForm;

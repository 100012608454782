import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";


function RegisterPharmacy() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    address: {
      district: "",
      city: "",
      town: "",
    },
    latitude: "",
    longitude: "",
    contacts: [""],
    admin_name: "",
    admin_contacts: [""],
    admin_email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name || formData.name.length > 255) {
      newErrors.name =
        "Pharmacy name is required and must be at most 255 characters long.";
    }

    if (!formData.address.city || formData.address.city.length > 255) {
      newErrors.address_city =
        "City is required and must be at most 255 characters long.";
    }

    if (formData.address.town && formData.address.town.length > 255) {
      newErrors.address_town = "Town must be at most 255 characters long.";
    }

    // if (
    //   formData.latitude === "" ||
    //   isNaN(formData.latitude) ||
    //   formData.latitude < -90 ||
    //   formData.latitude > 90
    // ) {
    //   newErrors.latitude =
    //     "Latitude is required and must be between -90 and 90.";
    // }

    // if (
    //   formData.longitude === "" ||
    //   isNaN(formData.longitude) ||
    //   formData.longitude < -180 ||
    //   formData.longitude > 180
    // ) {
    //   newErrors.longitude =
    //     "Longitude is required and must be between -180 and 180.";
    // }

    if (
      !Array.isArray(formData.contacts) ||
      formData.contacts.length === 0 ||
      formData.contacts.some((contact) => !contact || contact.length > 15)
    ) {
      newErrors.contacts =
        "At least one contact is required, and each contact must be at most 15 characters long.";
    }

    if (!formData.admin_name || formData.admin_name.length > 255) {
      newErrors.admin_name =
        "Admin name is required and must be at most 255 characters long.";
    }

    if (
      !Array.isArray(formData.admin_contacts) ||
      formData.admin_contacts.length === 0 ||
      formData.admin_contacts.some((contact) => !contact || contact.length > 15)
    ) {
      newErrors.admin_contacts =
        "At least one admin contact is required, and each contact must be at most 15 characters long.";
    }

    if (
      !formData.admin_email ||
      !/\S+@\S+\.\S+/.test(formData.admin_email) ||
      formData.admin_email.length > 255
    ) {
      newErrors.admin_email =
        "A valid email address is required and must be at most 255 characters long.";
    }

    if (!formData.password || formData.password.length < 8) {
      newErrors.password =
        "Password is required and must be at least 8 characters long.";
    }

    if (formData.password !== formData.password_confirmation) {
      newErrors.password_confirmation = "Passwords must match.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const onSuccess = () => {
      toast.success("Pharmacy Record Registered", { theme: "colored" });
      setFormData({
        name: "",
        address: {
          district: "",
          city: "",
          town: "",
        },
        latitude: "",
        longitude: "",
        contacts: [""],
        admin_name: "",
        admin_contacts: [""],
        admin_email: "",
        password: "",
        password_confirmation: "",
      });
      setCurrentStep(1);
      setLoading(false)
    };

    const onError = (error) => {
      toast.error(error?.response?.data.error || "Error Occurred", {
        theme: "colored",
      });
      setLoading(false);

    };

    apiRequest(
      "post",
      `${API_BASE_URL}pharmacies`,
      formData,
      "",
      onSuccess,
      onError
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: { ...prevData.address, [name]: value },
    }));
  };

  const handleArrayChange = (e, index, arrayName) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const updatedArray = [...prevData[arrayName]];
      updatedArray[index] = value;
      return { ...prevData, [arrayName]: updatedArray };
    });
  };

  const addArrayField = (arrayName) => {
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: [...prevData[arrayName], ""],
    }));
  };

  const removeArrayField = (index, arrayName) => {
    setFormData((prevData) => {
      const updatedArray = prevData[arrayName].filter((_, i) => i !== index);
      return { ...prevData, [arrayName]: updatedArray };
    });
  };

  const handleGeoLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude.toFixed(6),
            longitude: position.coords.longitude.toFixed(6),
          }));
          toast.success("Location captured successfully!", {
            theme: "colored",
          });
        },
        (error) => {
          toast.error("Failed to get location. Please try again.", {
            theme: "colored",
          });
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.", {
        theme: "colored",
      });
    }
  };

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Register New Pharmacy
          </h2>

          <form onSubmit={handleSubmit}>
            {/* Step 1: pharmacy Information */}
            {currentStep === 1 && (
              <div className="mt-4">
                <div className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold text-lg rounded-full shadow-sm">
                  Pharmacy Information
                </div>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  {/* Pharmacy Name */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="name"
                    >
                      Pharmacy Name
                    </label>
                    <input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.name && (
                      <p className="text-red-500">{errors.name}</p>
                    )}
                  </div>

                  {/* Pharmacy Address */}
                  <div>
                    <label className="text-gray-700 ">
                      Address
                    </label>
                    <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2">
                      {["district", "city", "town"].map((field) => (
                        <input
                          key={field}
                          name={field}
                          placeholder={
                            field.charAt(0).toUpperCase() + field.slice(1)
                          }
                          value={formData.address[field]}
                          onChange={handleAddressChange}
                          type="text"
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                        />
                      ))}
                    </div>
                  </div>

                  {/* Latitude */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="latitude"
                    >
                      Latitude
                    </label>
                    <input
                      name="latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      readOnly
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                  </div>

                  {/* Longitude */}
                  <div className="grid grid-cols-2 items-center gap-4">
                    {/* Longitude Label and Input Field */}
                    <div>
                      <label
                        className="text-gray-700  mr-2"
                        htmlFor="longitude"
                      >
                        Longitude
                      </label>
                      <input
                        name="longitude"
                        value={formData.longitude}
                        onChange={handleChange}
                        readOnly
                        type="text"
                        className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                      />
                      {errors.longitude && (
                        <p className="text-red-500">{errors.longitude}</p>
                      )}
                    </div>

                    {/* Get Current Location Button */}
                    <div className="flex mt-6 justify-evenly">
                      <button
                        onClick={handleGeoLocation}
                        className="border border-red-500 px-1 py-2 rounded-md text-red-500 bg-white hover:bg-red-500 hover:text-white"
                      >
                        Get Current Location
                      </button>
                    </div>
                  </div>

                  {/* Contacts */}
                  <div className="col-span-2">
                    <label
                      className="text-gray-700 "
                      htmlFor="contacts"
                    >
                     Phone Contacts
                    </label>
                    {formData.contacts.map((contact, index) => (
                      <div key={index} className="flex items-center mt-2">
                        <input
                          type="text"
                          value={contact}
                          onChange={(e) =>
                            handleArrayChange(e, index, "contacts")
                          }
                          className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                        />
                        <button
                          type="button"
                          onClick={() => removeArrayField(index, "contacts")}
                          className="ml-2 text-red-500 hover:underline"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addArrayField("contacts")}
                      className="mt-2 text-blue-500 hover:underline"
                    >
                      Add Contact
                    </button>
                  </div>
                </div>

                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={() => setCurrentStep(2)}
                    className="px-6 py-2 leading-5 text-white bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Step 2: Admin Information */}
            {currentStep === 2 && (
              <div className="mt-4">
                <div className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold text-lg rounded-full shadow-sm">
                  Admin Information
                </div>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  {/* Admin Name */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="admin_name"
                    >
                      Admin Name
                    </label>
                    <input
                      name="admin_name"
                      value={formData.admin_name}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.admin_name && (
                      <p className="text-red-500">{errors.admin_name}</p>
                    )}
                  </div>

                  {/* Admin Email */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="admin_email"
                    >
                      Admin Email
                    </label>
                    <input
                      name="admin_email"
                      value={formData.admin_email}
                      onChange={handleChange}
                      type="email"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.admin_email && (
                      <p className="text-red-500">{errors.admin_email}</p>
                    )}
                  </div>
                  {/* Admin Contacts */}
                  <div className="col-span-2">
                    <label
                      className="text-gray-700 "
                      htmlFor="admin_contacts"
                    >
                      Admin Contacts
                    </label>
                    {formData.admin_contacts.map((contact, index) => (
                      <div key={index} className="flex items-center mt-2">
                        <input
                          type="text"
                          value={contact}
                          onChange={(e) =>
                            handleArrayChange(e, index, "admin_contacts")
                          }
                          className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeArrayField(index, "admin_contacts")
                          }
                          className="ml-2 text-red-500 hover:underline"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addArrayField("admin_contacts")}
                      className="mt-2 text-blue-500 hover:underline"
                    >
                      Add Contact
                    </button>
                  </div>

                  {/* Admin Password */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      type="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password}</p>
                    )}
                  </div>

                  {/* Password Confirmation */}
                  <div>
                    <label
                      className="text-gray-700 "
                      htmlFor="password_confirmation"
                    >
                      Confirm Password
                    </label>
                    <input
                      name="password_confirmation"
                      value={formData.password_confirmation}
                      onChange={handleChange}
                      type="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {errors.password_confirmation && (
                      <p className="text-red-500">
                        {errors.password_confirmation}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    onClick={() => setCurrentStep(1)}
                    className="px-6 py-2 leading-5 text-white bg-gray-600 rounded-md hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
                  >
                    Previous
                  </button>

                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-white bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  >
                   {loading ?'Submitting ...': 'Submit'}
                  </button>
                </div>
              </div>
            )}
          </form>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RegisterPharmacy;

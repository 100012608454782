import React, { useState, useEffect } from "react";
import Nav from "../nav";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { toast, ToastContainer } from "react-toastify";
import { Select } from "antd";

const { Option } = Select;

function RegisterDoctor() {
  const [contacts, setContacts] = useState([""]);
    const [specializations, setSpecialization] = useState([]);
    const [loading, setLoading] = useState(false);
  const [userHospital, setUserHospital] = useState([]);


  const [formData, setFormData] = useState({
    hospital_id: "",
    name: "",
    specialization_id: [],
    email: "",
    password: "",
  });

  const addContactField = () => setContacts([...contacts, ""]);

  /**
   * @function handleContactChange
   * @param {*} index 
   * @param {*} value 
   */
  const handleContactChange = (index, value) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? value : contact
    );
    setContacts(updatedContacts);
  };

  /**
   * @function removeContactField
   * @param {*} index 
   */
  const removeContactField = (index) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

    const handleSpecializationChange = (value) => {
      setFormData({ ...formData, specialization_id: value });
    };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();

    data.append("hospital_id", userHospital?.hospital?.id);
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("password", formData.password);

    // Append the specialization_id as an array
    formData.specialization_id.forEach((id, index) => {
      data.append(`specialization_id[${index}]`, id);
    });
    contacts.forEach((contact, index) => {
      data.append(`contacts[${index}]`, contact);
    });

    const onSuccess = (response) => {
      toast.success("record saved", {
        theme: "colored",
      });
      setLoading(false);
      setFormData({
        hospital_id: "",
        name: "",
        specialization_id: [],
        email: "",
        password: "",
      });
    };
    const onError = (error) => {
      setLoading(false);
      toast.error("error occured while saving record", {
        theme: "colored",
      });
    };
    apiRequest("post", `${API_BASE_URL}doctors`, data, "", onSuccess, onError);
  };
 
  const getUserHospital = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserHospital(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      // toast.error("Error Occurred");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const fetchSpecialization = () =>{
    setLoading(true);
    const onSuccess = (response) =>{
        setLoading(false);
        setSpecialization(response)
    }
    const onError =(error) =>{
        toast.error('error occured');
        setLoading(false)
    }
    apiRequest("get", `${API_BASE_URL}services`, "", "", onSuccess, onError);
  }

  useEffect(() => {
    fetchSpecialization();
    getUserHospital();
  }, []);


  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Register Doctor
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="hospital_name"
                >
                  Hospital
                </label>
                <p
                  id="hospital_name"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md "
                >
                  {userHospital?.hospital?.hosp_name || "No hospital assigned"}
                </p>
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="specialization_ids"
                >
                  Specialization
                </label>
                <Select
                  mode="multiple"
                  value={formData.specialization_id}
                  onChange={handleSpecializationChange}
                  className="w-full"
                  placeholder="Select Specialization(s)"
                >
                  {specializations.map((specialization) => (
                    <Option key={specialization.id} value={specialization.id}>
                      {specialization.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>
              <div className="col-span-2">
                <label
                  className="text-gray-700 "
                  htmlFor="contacts"
                >
                  Phone Contacts
                </label>
                {contacts.map((contact, index) => (
                  <div key={index} className="flex items-center mt-2">
                    <input
                      id={`contact-${index}`}
                      type="text"
                      value={contact}
                      onChange={(e) =>
                        handleContactChange(index, e.target.value)
                      }
                      className="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    />
                    {contacts.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeContactField(index)}
                        className="ml-2 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addContactField}
                  className="mt-2 text-blue-600 hover:text-blue-800"
                >
                  Add Phone Contact
                </button>
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </section>
      </div>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default RegisterDoctor;

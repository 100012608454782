import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";

const UpdateHospitalDetailsModal = ({
  isVisible,
  handleClose,
  selectedHospital,
  handleUpdate,
  loading
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (selectedHospital) {
      form.setFieldsValue({
        hosp_name: selectedHospital.hosp_name,
        hosp_address_district: selectedHospital.hosp_address.district,
        hosp_address_city: selectedHospital.hosp_address.city,
        hosp_address_town: selectedHospital.hosp_address.town,
        hosp_contacts: selectedHospital.hosp_contacts.join(", "), // Convert array to comma-separated string
        hosp_admin: selectedHospital.hosp_admin,
        hosp_admin_contacts: selectedHospital.hosp_admin_contacts,
        hosp_admin_email: selectedHospital.hosp_admin_email,
        latitude: selectedHospital.latitude,
        longitude: selectedHospital.longitude,
      });
    }
  }, [selectedHospital, form]);

  /**
   * @function onFinish
   * @param {*} values 
   */
  const onFinish = (values) => {
    setIsSubmitting(true);

    // Convert comma-separated string back to an array
    const contactsArray = values.hosp_contacts
      .split(",")
      .map((contact) => contact.trim());

    const updatedDetails = {
      hosp_name: values.hosp_name,
      hosp_address: {
        district: values.hosp_address_district,
        city: values.hosp_address_city,
        town: values.hosp_address_town,
      },
      hosp_contacts: contactsArray,
      hosp_admin: values.hosp_admin,
      hosp_admin_contacts: values.hosp_admin_contacts,
      hosp_admin_email: values.hosp_admin_email,
      latitude: values.latitude,
      longitude: values.longitude,
    };

    handleUpdate(updatedDetails);
    setIsSubmitting(false);
  };

  return (
    <Modal
      title="Update Hospital Details"
      open={isVisible}
      onCancel={handleClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={selectedHospital}
      >
        <Form.Item
          name="hosp_name"
          label="Hospital Name"
          rules={[
            { required: true, message: "Please input the hospital name!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hosp_address_district"
          label="District"
          rules={[{ required: true, message: "Please input the district!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hosp_address_city"
          label="City"
          rules={[{ required: true, message: "Please input the city!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hosp_address_town"
          label="Town"
          rules={[{ required: true, message: "Please input the town!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="hosp_contacts"
          label="Contacts"
          rules={[
            { required: true, message: "Please input the hospital contacts!" },
          ]}
        >
          <Input.TextArea placeholder="Enter contacts separated by commas" />
        </Form.Item>
        <Form.Item
          name="hosp_admin"
          label="Admin Name"
          rules={[{ required: true, message: "Please input the admin name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="hosp_admin_contacts" label="Admin Contacts">
          <Input />
        </Form.Item>
        <Form.Item
          name="hosp_admin_email"
          label="Admin Email"
          rules={[{ required: true, message: "Please input the admin email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item disabled name="latitude" label="Latitude">
          <Input disabled />
        </Form.Item>
        <Form.Item disabled name="longitude" label="Longitude">
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" disabled={loading} htmlType="submit" loading={isSubmitting}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateHospitalDetailsModal;

import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { apiRequest } from "../../common/common"; // Ensure this is correctly implemented
import { API_BASE_URL } from "../../common/constants";
import { toast, ToastContainer } from "react-toastify";

function UpdateDoctorDetails({ visible, onClose, doctor, onUpdate }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [specializations, setSpecializations] = useState([]); // Initialize as an empty array

  useEffect(() => {
    if (visible) {
      fetchSpecializations();
    }
  }, [visible]);
  const fetchSpecializations = () => {
    setLoading(true);
    const onSuccess = (response) => {
      toast.success("Specializations fetched successfully");
      setLoading(false);
      // Assuming response.data contains the specializations array
      setSpecializations(response || []); // Default to an empty array if response.data is undefined
    };
    const onError = (error) => {
      toast.error("Error occurred while fetching specializations");
      setLoading(false);
    };
    apiRequest("get", `${API_BASE_URL}services`, "", "", onSuccess, onError);
  };

  const handleUpdate = () => {
    setLoading(true)
    form.validateFields().then((values) => {
      // Ensure contacts is formatted as an array of strings
      values.contacts = values.contacts
        .split(",")
        .map((contact) => contact.trim());
      const onSuccess = (response) => {
        toast.success("Update Successful", {
          theme: "colored",
        });
        setLoading(false)
        onUpdate();
        onClose();
      };

      const onError = (error) => {
        toast.error("Error Occured during Updated", {
          theme: "colored",
        });
        setLoading(false)
      };

      apiRequest(
        "patch",
        `${API_BASE_URL}doctors/${doctor.id}`,
        values,
        "",
        onSuccess,
        onError
      );
    });
  };

  return (
    <Modal
      open={visible}
      title="Update Doctor Details"
      okText="Update"
      cancelText="Cancel"
      onCancel={onClose}
      onOk={handleUpdate}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: doctor.name,
          specialization_id: doctor.specialization.id,
          contacts: doctor.contacts.join(", "),
          email: doctor.email,
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please input the doctor's name!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="specialization_id"
          label="Specialization"
          rules={[
            { required: true, message: "Please select a specialization!" },
          ]}
        >
          <Select defaultValue={doctor.specialization.id} loading={loading}>
            {specializations.length > 0 ? (
              specializations.map((specialization) => (
                <Select.Option
                  key={specialization.id}
                  value={specialization.id}
                >
                  {specialization.name}
                </Select.Option>
              ))
            ) : (
              <Select.Option disabled>
                No specializations available
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item name="contacts" label="Contacts">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <ToastContainer />
    </Modal>
  );
}

export default UpdateDoctorDetails;

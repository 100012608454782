import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";
import { FaUserDoctor } from "react-icons/fa6";
import UpdateDoctorDetails from "./UpdateDoctorDetails";
import Swal from "sweetalert2";

function ViewDoctors() {
  const [userHospital, setUserHospital] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getUserHospital = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserHospital(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("error Occured");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const fetchDoctors = () => {
    setLoading(true);
    const onSuccess = (response) => {
      toast.success("Doctors fetched successfully", {
        theme: "colored",
      });
      setDoctors(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error occurred while fetching doctors");
      setLoading(false);
    };
    const params = {
      hospital_id: userHospital?.hospital?.id,
    };
    apiRequest("get", `${API_BASE_URL}doctors`, "", params, onSuccess, onError);
  };

  /**
   * @function handleEditClick
   * @param {*} doctor
   * @description sets doctors object and trigeer modal open event
   */
  const handleEditClick = (doctor) => {
    setSelectedDoctor(doctor);
    setIsModalVisible(true);
  };

  const handleUpdate = () => {
    fetchDoctors();
  };

  const deActivate = (doctor) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to deactivate Dr. ${doctor.name} Account?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, deactivate!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        const onSuccess = (response) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Deactivation Successful",
            text: `Dr. ${doctor.name} has been deactivated.`,
          });
        };

        const onError = (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error Occurred",
            text: "An error occurred during deactivation.",
          });
        };

        apiRequest(
          "put",
          `${API_BASE_URL}doctors/${doctor.id}/deactivate`,
          "",
          "",
          onSuccess,
          onError
        );
      }
    });
  };
  useEffect(() => {
    getUserHospital();
  }, []);

  useEffect(() => {
    if (userHospital?.hospital?.id) {
      fetchDoctors();
    }
  }, [userHospital]);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="container px-4 mx-auto">
          <div className="flex items-center gap-x-3">
            <h2 className="text-lg font-medium text-gray-800 ">
              Doctors
            </h2>
            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full  ">
              {doctors.length} users
            </span>
          </div>

          <div className="flex flex-col mt-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 ">
                          <div className="flex items-center gap-x-3">
                            <input
                              type="checkbox"
                              className="text-blue-500 border-gray-300 rounded  "
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Contacts
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Email address
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Hospital
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Specialization
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Status
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200  ">
                      {doctors ? (
                        doctors.map((doctor) => (
                          <tr key={doctor.id}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded  "
                                />
                                <div className="flex items-center gap-x-2">
                                  <FaUserDoctor className="object-cover w-10 h-10 rounded-full" />
                                  <div>
                                    <h2 className="font-medium text-gray-800 ">
                                      {doctor.name}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {doctor.contacts.join(", ")}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {doctor.email}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {doctor.hospital.hosp_name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {doctor.specializations.map((spec, key) => (
                                <span key={key}  className="border border-blue-500 py-1 px-1 rounded-md text-blue-500 m-0.5">{spec.name}</span>
                              ))}
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div
                                className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                                  doctor.is_active
                                    ? "bg-emerald-100/60  text-emerald-500"
                                    : "bg-red-100/60  text-red-500"
                                }`}
                              >
                                <span
                                  className={`h-1.5 w-1.5 rounded-full ${
                                    doctor.is_active
                                      ? "bg-emerald-500"
                                      : "bg-red-500"
                                  }`}
                                ></span>
                                <h2 className="text-sm font-normal">
                                  {doctor.is_active ? "Active" : "Inactive"}
                                </h2>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() => deActivate(doctor)}
                                  className="text-gray-500 transition-colors duration-200  hover:text-red-500 focus:outline-none"
                                >
                                  {loading ? (
                                    "deactivating..."
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                      />
                                    </svg>
                                  )}
                                </button>
                                <button
                                  className="text-gray-500 transition-colors duration-200  hover:text-yellow-500 focus:outline-none"
                                  onClick={() => handleEditClick(doctor)}
                                >
                                  {loading ? (
                                    "Editing..."
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                      />
                                    </svg>
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="font-bold text-2xl flex justify-center">
                          No Doctors Found
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {selectedDoctor && (
        <UpdateDoctorDetails
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          doctor={selectedDoctor}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
}

export default ViewDoctors;

import React, { useEffect, useState } from "react";
import Nav from "../../nav";
import { RiAdminFill } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";
import UpdateAdminDetails from "./UpdateAdminDetails";
import { Modal } from "antd";
import Swal from "sweetalert2";

function ViewHospitalAdmin() {
  const [hospAdmins, setHospAdmins] = useState([]);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [selectedAdmin, setSelectedAdmin] = useState(null);
   const [refresh, setRefresh] = useState(false);
   const [loading , setLoading] = useState(false);
  const [userHospital, setUserHospital] = useState([]);


   /**
    * @function handleUpdateClick
    * @param {*} admin 
    * @description sets admin record and opens the update modal
    */
   const handleUpdateClick = (admin) => {
     setSelectedAdmin(admin);
     setIsModalVisible(true);
   };

   const handleModalOk = () => {
     // Handle form submission
     setIsModalVisible(false);
   };

   const handleModalCancel = () => {
     setIsModalVisible(false);
   };

    const getUserHospital = () => {
      setLoading(true);
      const onSuccess = (response) => {
        setUserHospital(response.data);
        setLoading(false);
      };
      const onError = (error) => {
        // toast.error("Error Occurred");
        setLoading(false);
      };
      apiRequest(
        "get",
        `${API_BASE_URL}user/hospital`,
        "",
        "",
        onSuccess,
        onError
      );
    };

  const fetchHospitalAdmins = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setHospAdmins(response.data);
      // toast.success("Success", {
      //   theme: "colored",
      // });
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
      setLoading(false);
    };
    let param = {
      hospital_id:userHospital?.hospital?.id
    }
    apiRequest(
      "get",
      `${API_BASE_URL}admin/hospital-admins`,
      "",
      param,
      onSuccess,
      onError
    );
  };

  /**
   * @function deActivate
   * @param {*} admin 
   * @description deatcivate admin accoount
   */
  const deActivate = (admin) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to deactivate ${admin.name} Account?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, deactivate!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        const onSuccess = (response) => {
          Swal.fire({
            icon: "success",
            title: "Deactivation Successful",
            text: `${admin.name} has been deactivated.`,
          });
          fetchHospitalAdmins();
        };

        const onError = (error) => {
          Swal.fire({
            icon: "error",
            title: "Error Occurred",
            text: "An error occurred during deactivation.",
          });
        };

        apiRequest(
          "patch",
          `${API_BASE_URL}admin/hospital-admins/${admin.id}/deactivate`,
          "",
          "",
          onSuccess,
          onError
        );
      }
    });
  };
  useEffect(() => {
    getUserHospital();
  }, []);
  useEffect(() => {
    fetchHospitalAdmins();
  }, [refresh]);

   useEffect(() => {
    if (userHospital?.hospital?.id) {
     fetchHospitalAdmins();
    }
   }, [userHospital]);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="container px-4 mx-auto">
          <div className="flex items-center gap-x-3">
            <h1 className="text-lg font-medium text-gray-800 ">
              Hospital Admins
            </h1>

            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full  ">
              {hospAdmins.length} hospital admins
            </span>
          </div>

          <div className="flex flex-col mt-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          <div className="flex items-center gap-x-3">
                            <input
                              type="checkbox"
                              className="text-blue-500 border-gray-300 rounded   "
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          <button className="flex items-center gap-x-2">
                            <span>Status</span>
                          </button>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Email address
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Contact
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Hospital Name
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200  ">
                      {loading ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index} className="animate-pulse">
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-12 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                          </tr>
                        ))
                      ) : hospAdmins ? (
                        hospAdmins.map((admin) => (
                          <tr key={admin.id}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded   "
                                />
                                <div className="flex items-center gap-x-2">
                                  <RiAdminFill className="w-6 h-10 rounded-full" />
                                  <div>
                                    <h2 className="font-medium text-gray-800 ">
                                      {admin.name}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div
                                className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                                  admin.status === "active"
                                    ? "bg-emerald-100/60 text-emerald-500"
                                    : "bg-red-100/60 text-red-500"
                                }`}
                              >
                                <span
                                  className={`h-1.5 w-1.5 rounded-full ${
                                    admin.status === "active"
                                      ? "bg-emerald-500"
                                      : "bg-red-500"
                                  }`}
                                ></span>
                                <h2 className="text-sm font-normal">
                                  {admin.status.charAt(0).toUpperCase() +
                                    admin.status.slice(1)}
                                </h2>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {admin.email}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {JSON.parse(admin.contact).map(
                                (contact, index) => (
                                  <span
                                    className="border border-blue-500 rounded-md text-blue-500 py-1 px-1"
                                    key={index}
                                  >
                                    {contact}
                                    {index < admin.contact.length - 1}
                                  </span>
                                )
                              )}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {admin.hospital.hosp_name}
                            </td>

                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() => deActivate(admin)}
                                  className="text-gray-500 transition-colors duration-200  hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.034.681-.079 1.022-.142m13.016.219a8.629 8.629 0 011.596.651m-1.596-.65a48.455 48.455 0 00-8.04 0"
                                    />
                                  </svg>
                                </button>

                                <button
                                  onClick={() => handleUpdateClick(admin)}
                                  className="text-gray-500 transition-colors duration-200  hover:text-blue-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6.633 17.56l4.181 4.18c.079.079.16.16.246.24m4.16-4.174l4.18-4.18a1.5 1.5 0 00.225-2.04l-.228-.226a1.5 1.5 0 00-2.029-.226l-4.182 4.182M6.633 17.56L3 21m3.633-3.44l4.18 4.18a1.5 1.5 0 002.115.095l7.056-7.053a1.5 1.5 0 00.22-2.057L13.683 7.22a1.5 1.5 0 00-2.055-.22l-7.065 7.056a1.5 1.5 0 00-.072 2.114L6.633 17.56z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="font-bold text-2xl flex justify-content-center">
                          No Hospital Admins Found
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        title="Update Admin Details"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={null}
      >
        <UpdateAdminDetails
          admin={selectedAdmin}
          refresh={refresh}
          setRefresh={setRefresh}
          onClose={handleModalCancel}
        />
      </Modal>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default ViewHospitalAdmin;

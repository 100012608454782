import React, { useEffect, useState } from "react";
import Nav from "../nav";
import { toast, ToastContainer } from "react-toastify";
import imageCompression from "browser-image-compression";
import { apiRequest, apiRequestWithImage } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function RegisterMedicineStock() {
  const [loading, setLoading] = useState(false);
  const [drugCategories, setDrugCategories] = useState([]);
  const [userPharmacy, setUserPharmacy] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    stock: 0,
    price: 0,
    dosage_form: "",
    manufacturer: "",
    warnings: "",
    side_effects: "",
    category_id: "",
    pharmacy_id: "",
    buying_price: "",
    thumbnail: "",
  });

  const getDrugCategories = () => {
    setLoading(true);
    const onSuccess = (response) => {
      if (Array.isArray(response) && Array.isArray(response[0])) {
        setDrugCategories(response[0]);
      }
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}categories`, "", "", onSuccess, onError);
  };

  const getUserPharmacy = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserPharmacy(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      console.log(error);
      toast.error("Error Occurred");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    // Options for image compression
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 1024, // Maximum width or height
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setFormData({ ...formData, thumbnail: compressedFile });
    } catch (error) {
      toast.error("Image compression failed");
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const onSuccess = (response) => {
      toast.success("Drug Registered", {
        theme: "colored",
      });
      setLoading(false);
      setFormData({
        name: "",
        description: "",
        stock: 0,
        price: 0,
        dosage_form: "",
        manufacturer: "",
        delivery_options: "",
        warnings: "",
        side_effects: "",
        category_id: "",
      });
    };
    const onError = (error) => {
      toast.error("Error Occured", {
        theme: "colored",
      });
      setLoading(false);
    };
    apiRequestWithImage(
      "post",
      `${API_BASE_URL}medicines`,
      formData,
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getDrugCategories();
    getUserPharmacy();
  }, []);

  useEffect(() => {
    if (userPharmacy) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        pharmacy_id: userPharmacy?.pharmacy?.id,
      }));
    }
  }, [userPharmacy]);

  console.log(formData);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <h1 className="mb-8">Medicine Stock Form</h1>
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Add Stock Form
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              {/* Name Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>

              {/* Description Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>

              {/* Category ID Field as Dropdown */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="category_id"
                >
                  Category
                </label>
                <select
                  id="category_id"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                >
                  <option value="" disabled>
                    Select a Category
                  </option>
                  {drugCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Dosage Form Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="dosage_form"
                >
                  Dosage Form
                </label>
                <select
                  id="dosage_form"
                  name="dosage_form"
                  value={formData.dosage_form}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                >
                  <option value="">Select Dosage Form</option>
                  <option value="tablet">Tablet</option>
                  <option value="capsule">Capsule</option>
                  <option value="syrup">Syrup</option>
                  <option value="injection">Injection</option>
                  <option value="ointment">Ointment</option>
                  <option value="cream">Cream</option>
                  <option value="gel">Gel</option>
                  <option value="suppository">Suppository</option>
                  <option value="patch">Patch</option>
                  <option value="inhaler">Inhaler</option>
                  <option value="solution">Solution</option>
                  <option value="suspension">Suspension</option>
                  <option value="powder">Powder</option>
                  <option value="lotion">Lotion</option>
                </select>
              </div>

              {/* Stock Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="stock"
                >
                  Stock
                </label>
                <input
                  id="stock"
                  name="stock"
                  type="number"
                  value={formData.stock}
                  onChange={handleChange}
                  min="0"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>

              {/* Buying Price Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="price"
                >
                  Buying Price
                </label>
                <input
                  id="buying_price"
                  name="buying_price"
                  type="number"
                  value={formData.buying_price}
                  onChange={handleChange}
                  min="0"
                  step="0.01"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>

              {/* Price Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="price"
                >
                  Selling Price
                </label>
                <input
                  id="price"
                  name="price"
                  type="number"
                  value={formData.price}
                  onChange={handleChange}
                  min="0"
                  step="0.01"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>

              {/* Manufacturer Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="manufacturer"
                >
                  Manufacturer
                </label>
                <input
                  id="manufacturer"
                  name="manufacturer"
                  type="text"
                  value={formData.manufacturer}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>

              {/* Warnings Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="warnings"
                >
                  Warnings
                </label>
                <textarea
                  id="warnings"
                  name="warnings"
                  value={formData.warnings}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>

              {/* Side Effects Field */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="side_effects"
                >
                  Side Effects
                </label>
                <textarea
                  id="side_effects"
                  name="side_effects"
                  value={formData.side_effects}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>

              {/* Thumbnail Upload */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="thumbnail"
                >
                  Thumbnail
                </label>
                <input
                  id="thumbnail"
                  name="thumbnail"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  required
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-6 py-2 leading-5 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none"
                disabled={loading}
              >
                {loading ? "Registering..." : "Register Stock"}
              </button>
            </div>
          </form>
        </section>
        <ToastContainer />
      </div>
    </div>
  );
}

export default RegisterMedicineStock;

import React, { useEffect, useState } from "react";
import Nav from "../nav";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { toast, ToastContainer } from "react-toastify";
import UpdateDetails from "./UpdateDetails";
import ImageOverlay from "./ImageOverlay";

function MedicineStockTable() {
  const [loading, setLoading] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [userPharmacy, setUserPharmacy] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const getUserPharmacy = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserPharmacy(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      console.log(error);
      toast.error("Error Occurred");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const showModal = (drug) => {
    setSelectedDrug(drug);
    setUpdateModal(true);
  };

  const handleCloseModal = () => {
    setUpdateModal(false);
    setSelectedDrug(null);
  };

  const getMedicines = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setMedicines(response);
      setLoading(false);
    };
    const onError = (error) => {
      setLoading(false);
      toast.error("Error Occurred", {
        theme: "colored",
      });
    };
    let params = {
      pharmacy_id: userPharmacy.pharmacy.id,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}medicines`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (userPharmacy?.pharmacy?.id) {
      getMedicines();
    }
  }, [userPharmacy, refresh]);

  useEffect(() => {
    getUserPharmacy();
  }, []);

  const getStatus = (stock) => {
    if (stock > 20) {
      return (
        <span className="inline-block px-2 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 whitespace-nowrap">
          In Stock
        </span>
      );
    } else if (stock > 0 && stock <= 20) {
      return (
        <span className="inline-block px-2 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800 whitespace-nowrap">
          Low Stock
        </span>
      );
    } else {
      return (
        <span className="inline-block px-2 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 whitespace-nowrap">
          Out of Stock
        </span>
      );
    }
  };

  const viewImage = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setOverlayVisible(!overlayVisible);
  };

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <h1 className="mb-8">Medicine Stock</h1>
        <section className="max-w-5xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Stock List
          </h2>

          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Dosage Form
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Stock
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Manufacturer
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {medicines.length > 0 ? (
                medicines.map((medicine) => (
                  <tr key={medicine.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.name}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.category.name}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.dosage_form}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.stock}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.price}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {getStatus(medicine.stock)}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {medicine.manufacturer}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <button
                        onClick={() => viewImage(medicine.thumbnail)}
                        className="text-gray-500 transition-colors duration-200  hover:text-blue-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          color="#000000"
                          fill="none"
                        >
                          <circle
                            cx="7.5"
                            cy="7.5"
                            r="1.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M5 21C9.37246 15.775 14.2741 8.88406 21.4975 13.5424"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>
                      </button>
                    </td>
                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                      <div className="flex items-center gap-x-6">
                        <button className="text-gray-500 transition-colors duration-200  hover:text-red-500 focus:outline-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>

                        <button
                          onClick={() => {
                            showModal(medicine);
                          }}
                          className="text-gray-500 transition-colors duration-200  hover:text-yellow-500 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="font-bold text-center py-4">
                    No Drugs found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </div>
      <UpdateDetails
        visible={updateModal}
        setOpen={setUpdateModal}
        drug={selectedDrug}
        onClose={handleCloseModal}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <ImageOverlay
        visible={overlayVisible}
        imageUrl={selectedImageUrl}
        onClose={() => setOverlayVisible(false)}
      />
      <ToastContainer />
    </div>
  );
}

export default MedicineStockTable;

import React, { useState, useEffect } from "react";
import Nav from "../../nav";
import { FaHospital } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { apiRequest } from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";
import UpdateHospitalDetailsModal from "./UpdateHospitalDetails";
import Swal from "sweetalert2";

function Hospitals() {
  const [hospitals, setHospitals] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEdit = (hospital) => {
    setSelectedHospital(hospital);
    setIsModalVisible(true);
  };

  const fetchHospitals = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setHospitals(response.data);
      // toast.success("Successfully retrieved hospital records.", {
      //   theme: "colored",
      // });
      setLoading(false);
    };

    const onError = () => {
      toast.error("Failed to retrieve hospital records.", {
        theme: "colored",
      });
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}admin/hospitals`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  /**
   * @function handleUpdate
   * @param {*} updatedDetails
   */
  const handleUpdate = (updatedDetails) => {
    setLoading(true);

    const onSuccess = () => {
      toast.success("Hospital details updated successfully.", {
        theme: "colored",
      });
      fetchHospitals(); // Refresh the hospital list
      setIsModalVisible(false);
      setLoading(false);
    };

    const onError = () => {
      toast.error("Failed to update hospital details.", {
        theme: "colored",
      });
      setLoading(false);
    };

    apiRequest(
      "put",
      `${API_BASE_URL}admin/hospitals/${selectedHospital.id}`,
      updatedDetails,
      "",
      onSuccess,
      onError
    );
  };

  const deActivate = (hospital) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to deactivate ${hospital.hosp_name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, deactivate!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        const onSuccess = (response) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Deactivation Successful",
            text: `${hospital.hosp_name} has been deactivated.`,
          });
          fetchHospitals();
        };

        const onError = (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error Occurred",
            text: "An error occurred during deactivation.",
          });
        };

        apiRequest(
          "put",
          `${API_BASE_URL}admin/hospitals/${hospital.id}/deactivate`,
          "",
          "",
          onSuccess,
          onError
        );
      }
    });
  };

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="container px-4 mx-auto">
          <div className="flex items-center gap-x-3">
            <h1 className="text-lg font-medium text-gray-800 ">
              Hospitals
            </h1>

            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full  ">
              {hospitals.length} hospitals
            </span>
          </div>

          <div className="flex flex-col mt-6">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500 ">
                          <div className="flex items-center gap-x-3">
                            <input
                              type="checkbox"
                              className="text-blue-500 border-gray-300 rounded "
                            />
                            <span>Name</span>
                          </div>
                        </th>
                        <th className="px-12 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          <button className="flex items-center gap-x-2">
                            <span>Hospital phone</span>
                          </button>
                        </th>
                        <th className="px-12 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          <button className="flex items-center gap-x-2">
                            <span>Status</span>
                          </button>
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Hosp Admin
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Admin Email Address
                        </th>
                        <th className="px-4 py-3.5 text-sm font-normal text-left text-gray-500 ">
                          Location
                        </th>
                        <th className="relative py-3.5 px-4">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200  ">
                      {loading ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index} className="animate-pulse">
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-12 py-4">
                              <div className="h-6 bg-gray-300  rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300 rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300 rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300 rounded"></div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="h-6 bg-gray-300 rounded"></div>
                            </td>
                          </tr>
                        ))
                      ) : hospitals ? (
                        hospitals.map((hospital) => (
                          <tr key={hospital.id}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded "
                                />

                                <div className="flex items-center gap-x-2">
                                  <FaHospital className="w-6 h-10 rounded-full" />
                                  <div>
                                    <h2 className="font-medium text-gray-800  ">
                                      {hospital.hosp_name}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              {hospital.hosp_contacts.map((contact, index) => (
                                <span className="border border-blue-500 rounded-md text-blue-500 py-1 px-1" key={index}>
                                  {contact}
                                  {index < hospital.hosp_contacts.length - 1}
                                </span>
                              ))}
                            </td>
                            <td className="px-12 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div
                                className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                                  hospital.status === "active"
                                    ? "bg-emerald-100/60"
                                    : "bg-red-100/60"
                                } `}
                              >
                                {/* <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span> */}

                                <h2
                                  className={`text-sm font-normal ${
                                    hospital.status === "active"
                                      ? " text-emerald-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {hospital.status}
                                </h2>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {hospital.hosp_admin}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              {hospital.hosp_admin_email}
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-2">
                                <p className="px-3 py-1 text-xs text-indigo-500 rounded-full  bg-indigo-100/60">
                                  {hospital.hosp_address.district}
                                </p>
                                <p className="px-3 py-1 text-xs text-blue-500 rounded-full  bg-blue-100/60">
                                  {hospital.hosp_address.city}
                                </p>
                                <p className="px-3 py-1 text-xs text-pink-500 rounded-full  bg-pink-100/60">
                                  {hospital.hosp_address.town}
                                </p>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() => deActivate(hospital)}
                                  className="text-gray-500 transition-colors duration-200  hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.034.681-.079 1.022-.142m13.016.219a8.629 8.629 0 011.596.651m-1.596-.65a48.455 48.455 0 00-8.04 0"
                                    />
                                  </svg>
                                </button>

                                <button
                                  className="text-gray-500 transition-colors duration-200  hover:text-blue-500 focus:outline-none"
                                  onClick={() => handleEdit(hospital)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6.633 17.56l4.181 4.18c.079.079.16.16.246.24m4.16-4.174l4.18-4.18a1.5 1.5 0 00.225-2.04l-.228-.226a1.5 1.5 0 00-2.029-.226l-4.182 4.182M6.633 17.56L3 21m3.633-3.44l4.18 4.18a1.5 1.5 0 002.115.095l7.056-7.053a1.5 1.5 0 00.22-2.057L13.683 7.22a1.5 1.5 0 00-2.055-.22l-7.065 7.056a1.5 1.5 0 00-.072 2.114L6.633 17.56z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="font-bold text-2xl flex justify-content-center">
                          No Hospitals Found
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
          <UpdateHospitalDetailsModal
            isVisible={isModalVisible}
            handleClose={() => setIsModalVisible(false)}
            selectedHospital={selectedHospital}
            handleUpdate={handleUpdate}
            loading={loading}
          />
        </section>
      </div>
    </div>
  );
}

export default Hospitals;

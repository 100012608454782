import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";
import AssignDoctor from "./AssignDoctor";

function ViewPatientInquiries() {
  const [patientInquiries, setPatientInquiries] = useState([]);
  const [userHospital, setUserHospital] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserHospital = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserHospital(response.data);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}user/hospital`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  // Function to handle modal open
  const showModal = (inquiry) => {
    setIsModalVisible(true);
    setSelectedInquiry(inquiry);
  };

  const fetchPatientInquiries = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setPatientInquiries(response.inquiries);
      setLoading(false);
    };
    const onError = () => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
      setLoading(false);
    };

    const param = {
      hospital_id: userHospital?.hospital?.id,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}patients/inquiries`,
      "",
      param,
      onSuccess,
      onError
    );
  };

  const renderJsonDetails = (json) => {
    if (!json) return "None";
    try {
      const obj = JSON.parse(json);
      return Object.entries(obj).map(([key, value]) => (
        <p key={key} className="text-gray-700 ">
          <span className="font-semibold">{key}:</span> {value}
        </p>
      ));
    } catch (e) {
      return "Invalid data";
    }
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "submitted for review":
        return "bg-blue-100 text-blue-800";
      case "doctor responding":
        return "bg-green-100 text-cyan-800";
      case "completed":
        return "bg-red-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  useEffect(() => {
    if (userHospital?.hospital?.id) {
      fetchPatientInquiries();
    }
  }, [userHospital, refresh]);

  useEffect(() => {
    getUserHospital();
  }, []);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <div className="overflow-x-auto rounded-lg shadow-md">
          <table className="min-w-full divide-y divide-gray-200  bg-white ">
            <thead className="bg-gray-50 ">
              <tr>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Hospital Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Patient Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Patient Address
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Inquiry Status
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500  uppercase tracking-wider">
                  Specialization
                </th>
              </tr>
            </thead>
            <tbody className="bg-white  divide-y divide-gray-200 ">
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} className="animate-pulse">
                    <td className="px-4 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                    <td className="px-12 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="h-6 bg-gray-300  rounded"></div>
                    </td>
                  </tr>
                ))
              ) : patientInquiries ? (
                patientInquiries.map((inquiry, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() => handleRowClick(index)}
                      className={`cursor-pointer hover:bg-gray-100  ${
                        index % 2 === 0
                          ? "bg-gray-50 "
                          : "bg-white "
                      }`}
                    >
                      <td className="py-4 px-6 text-sm font-medium text-gray-900 ">
                        {inquiry.hospital.hosp_name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.patient.name}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.patient.address}
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-semibold ${getStatusClass(
                            inquiry.inquiry_status.name
                          )}`}
                        >
                          {inquiry.inquiry_status.name}
                        </span>
                      </td>
                      <td className="py-4 px-6 text-sm text-gray-500 ">
                        {inquiry.specialization
                          ? inquiry.specialization.name
                          : "N/A"}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td
                          colSpan="5"
                          className="p-4 bg-gray-50 "
                        >
                          <div className="p-4 bg-white  rounded-lg shadow-md">
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Allergies:
                            </p>
                            <div className="ml-4 mb-4">
                              {renderJsonDetails(inquiry.patient.allergies)}
                            </div>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Medical History:
                            </p>
                            <div className="ml-4 mb-4">
                              {renderJsonDetails(
                                inquiry.patient.medical_history
                              )}
                            </div>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Gender:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.patient.gender || "Unknown"}
                            </p>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Contact:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.patient.contact || "Unknown"}
                            </p>
                            <p className="font-semibold text-lg text-gray-800  mb-2">
                              Inquiry Description:
                            </p>
                            <p className="ml-4 text-gray-700 ">
                              {inquiry.inquiry_description || "N/A"}
                            </p>

                            <div className=" float-end font-extralight">
                              {inquiry.status_id === 1 && (
                                <button
                                  onClick={() => showModal(inquiry)}
                                  className="py-2 px-4 rounded-md border hover:border-red-500 hover:bg-white hover:text-red-500 bg-red-500 text-white"
                                >
                                  Assign Doctor
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div className="font-bold text-2xl justify-content-center">
                  No Patient Inquiries Found
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <AssignDoctor
        open={isModalVisible}
        refresh={refresh}
        setRefresh={setRefresh}
        setOpen={setIsModalVisible}
        inquiry={selectedInquiry}
        setExpandedRow={setExpandedRow}

      />
    </div>
  );
}

export default ViewPatientInquiries;

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaHospital } from "react-icons/fa";
import { MdAddHome, MdStreetview, MdPersonAddAlt1 } from "react-icons/md";
import { FaUserDoctor, FaUsersViewfinder } from "react-icons/fa6";
import { BsCapsule } from "react-icons/bs";
import logo from "../../assets/img/logo.png"

import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { useHasUserRole } from "../../common/utility";

function Nav() {
  const [openHospMenu, setOpenHopMenu] = useState(false);
  const [pharmacyMenu, setPharmacyMenu] = useState(false);
  const history = useHistory(); 
  const isAdmin = useHasUserRole("admin");
  const isHospAdmin = useHasUserRole("hospital_admin");
  const isPharmAdmin = useHasUserRole("pharm_manager");
  const isDoctor = useHasUserRole("doctor");

  const handleHospMenu = () => {
    setOpenHopMenu(!openHospMenu);
    setPharmacyMenu(false);
  };

  const handlePharmMenu = () => {
    setPharmacyMenu(!pharmacyMenu);
    setOpenHopMenu(false);
  };

  const handleLogOut = () => {
    const onSuccess = (response) => {
      toast.success(response.message, {
        theme: "dark",
      });
      localStorage.removeItem("token");
       history.push("/");
    };

    const onError = () => {
      toast.error("Logout Failed", {
        theme: "colored",
      });
      localStorage.removeItem("token");
       history.push("/");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}auth/logout`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  return (
    <div>
      <aside className="flex">
        <div className="flex flex-col items-center w-16 h-screen py-8 bg-white  ">
          <nav className="flex flex-col items-center flex-1 space-y-8">
            <img
              className="w-auto h-6"
              src={logo}
              alt="Dokitar Hospital Logo"
            />

            <Link
              to={"/analytics"}
              className={`${
                !openHospMenu &&
                !pharmacyMenu &&
                "text-blue-500 bg-blue-100 rounded-lg  "
              } p-1.5 inline-block  transition-colors duration-200 `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </Link>

            {(isAdmin || isHospAdmin || isDoctor) && (
              <div
                onClick={handleHospMenu}
                className={`${
                  openHospMenu &&
                  "text-blue-500 bg-blue-100 rounded-lg  "
                } p-1.5 inline-block  transition-colors duration-200 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  color="#000000"
                  fill="none"
                >
                  <path
                    d="M14 2V4M14 4V6M14 4H10M10 2V4M10 4V6"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3 22V11.3808C3 7.8766 3 6.12452 4.15327 5.03591C4.88623 4.34404 5.90312 4.09189 7.5 4M21 22V11.3808C21 7.8766 21 6.12452 19.8467 5.03591C19.1138 4.34404 18.0969 4.09189 16.5 4"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 10H16"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M14 14H16"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7 14H9"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7 10H9"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M2 22H9.5M22 22H14.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 22V19.5C9.5 18.5654 9.5 18.0981 9.70096 17.75C9.83261 17.522 10.022 17.3326 10.25 17.201C10.5981 17 11.0654 17 12 17C12.9346 17 13.4019 17 13.75 17.201C13.978 17.3326 14.1674 17.522 14.299 17.75C14.5 18.0981 14.5 18.5654 14.5 19.5V22"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            )}

            {(isPharmAdmin || isAdmin) && (
              <div
                onClick={handlePharmMenu}
                className={`${
                  pharmacyMenu &&
                  "text-blue-500 bg-blue-100 rounded-lg  "
                } p-1.5 inline-block  transition-colors duration-200 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  color="#000000"
                  fill="none"
                >
                  <path
                    d="M11 7H17M11 5V9C11 10.6569 12.3431 12 14 12C15.6569 12 17 10.6569 17 9V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 14H6.39482C6.68897 14 6.97908 14.0663 7.24217 14.1936L9.28415 15.1816C9.54724 15.3089 9.83735 15.3751 10.1315 15.3751H11.1741C12.1825 15.3751 13 16.1662 13 17.142C13 17.1814 12.973 17.2161 12.9338 17.2269L10.3929 17.9295C9.93707 18.0555 9.449 18.0116 9.025 17.8064L6.84211 16.7503"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13 16.5L17.5928 15.0889C18.407 14.8352 19.2871 15.136 19.7971 15.8423C20.1659 16.3529 20.0157 17.0842 19.4785 17.3942L11.9629 21.7305C11.4849 22.0063 10.9209 22.0736 10.3952 21.9176L4 20.0199"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}

            {/* <Link
              to="#"
              className="p-1.5 inline-block text-gray-500 focus:outline-none transition-colors duration-200 rounded-lg   hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </Link> */}

            {
              <div
                onClick={handleLogOut}
                className="p-1.5 inline-block text-gray-500 focus:outline-none transition-colors duration-200 rounded-lg   hover:bg-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  color="#000000"
                  fill="none"
                >
                  <path
                    d="M7.02331 5.5C4.59826 7.11238 3 9.86954 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 9.86954 19.4017 7.11238 16.9767 5.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 2V10"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            }
          </nav>
        </div>

        <div
          className={`${
            openHospMenu ? "" : "hidden"
          } h-screen py-8 overflow-y-auto bg-white border-l border-r sm:w-64 w-60  `}
        >
          <h2 className="px-5 text-lg font-medium text-gray-800 ">
            HMS
          </h2>

          <div className="mt-8 space-y-4">
            <div
              className={` ${
                isAdmin || isHospAdmin ? "" : "hidden"
              }mt-2 border-t border-gray-300 `}
            ></div>
            <label
              className={`${
                isAdmin || isHospAdmin ? "" : "hidden"
              } px-3 text-xs text-gray-500 uppercase `}
            >
              Hospitals
            </label>

            {isAdmin && (
              <Link
                to={"/hospital/register"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <MdAddHome className="object-cover w-8 h-8 rounded-full" />

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Register Hospitals
                  </h1>
                </div>
              </Link>
            )}
            {(isAdmin || isHospAdmin) && (
              <Link
                to={"/hospitals"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <FaHospital className="object-cover w-8 h-8 rounded-full" />

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    {isAdmin ? "View Hospitals" : "Hospital"}
                  </h1>
                </div>
              </Link>
            )}
            <div
              className={`${
                isAdmin || isHospAdmin ? "" : "hidden"
              }mt-2 border-t border-gray-300 `}
            ></div>
            <label
              className={`px-3 text-xs text-gray-500 uppercase  ${
                isAdmin || isHospAdmin ? "" : "hidden"
              }`}
            >
              Hospital Admins
            </label>
            {isAdmin && (
              <Link
                to={"/register/hosp/admins"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <MdPersonAddAlt1 className="object-cover w-8 h-8 rounded-full" />

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Register Hospital Admin
                  </h1>
                </div>
              </Link>
            )}

            {(isAdmin || isHospAdmin) && (
              <Link
                to={"/hosp/admins"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <MdStreetview className="object-cover w-8 h-8 rounded-full" />
                </div>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Hospital Admins
                  </h1>
                </div>
              </Link>
            )}

            {isHospAdmin && (
              <>
                {" "}
                <div className="mt-2 border-t border-gray-300 "></div>
                <label className="px-3 text-xs text-gray-500 uppercase ">
                  Doctors
                </label>
              </>
            )}

            {isHospAdmin && (
              <Link
                to={"/register/doctors"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Register Doctors
                  </h1>
                </div>
              </Link>
            )}
            {isHospAdmin && (
              <Link
                to={"/view/doctors"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <FaUsersViewfinder className="object-cover w-8 h-8 rounded-full" />

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    {isAdmin || isHospAdmin ? " View Doctors" : "Doctor"}
                  </h1>
                </div>
              </Link>
            )}

            {isHospAdmin && (
              <Link
                to={"/patient/inquires"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Patient Inquiries
                  </h1>
                </div>
              </Link>
            )}
            {!isAdmin && !isHospAdmin && (
              <Link
                to="/doctor/inquiries"
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>
                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Inquiry Requests
                  </h1>
                </div>
              </Link>
            )}

            {/* <button className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none">
              <div className="relative">
                <img
                  className="object-cover w-8 h-8 rounded-full"
                  src="https://images.unsplash.com/photo-1608174386344-80898cec6beb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&h=687&q=80"
                  alt=""
                />
                <span className="h-2 w-2 rounded-full bg-emerald-500 absolute right-0.5 ring-1 ring-white bottom-0"></span>
              </div>

              <div className="text-left rtl:text-right">
                <h1 className="text-sm font-medium text-gray-700 capitalize ">
                  Junior REIS
                </h1>

                <p className="text-xs text-gray-500 ">
                  56.6 Followers
                </p>
              </div>
            </button> */}
          </div>
        </div>

        <div
          className={`${
            pharmacyMenu ? "" : "hidden"
          } h-screen py-8 overflow-y-auto bg-white border-l border-r sm:w-64 w-60  `}
        >
          <h2 className="px-5 text-lg font-medium text-gray-800 ">
            Pharmacy
          </h2>

          <div
            className={`mt-2 border-t border-gray-300 `}
          ></div>
          <label
            className={` px-3 text-xs text-gray-500 uppercase `}
          >
            Pharmacies
          </label>
          {isAdmin && (
            <>
              <Link
                to="/pharmacies/register"
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>
                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Register Pharmacy
                  </h1>
                </div>
              </Link>
              <Link
                to="/pharmacies/view"
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>
                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    View Pharmacies
                  </h1>
                </div>
              </Link>
              <Link
                to="/pharmacies/register/admins"
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>
                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Register Pharmacy Admins
                  </h1>
                </div>
              </Link>
              <Link
                to="/pharmacies/view/admins"
                className="flex items-center w-full px-5 py-2 transition-colors duration-200 hover:bg-gray-100  gap-x-2 focus:outline-none"
              >
                <div className="relative">
                  <FaUserDoctor className="object-cover w-8 h-8 rounded-full" />
                </div>
                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    View Pharmacy Admins
                  </h1>
                </div>
              </Link>
            </>
          )}
          <div className="mt-8 space-y-4">
            <div
              className={`${
                !isPharmAdmin && "hidden"
              }mt-2 border-t border-gray-300 `}
            ></div>
            <label
              className={`${
                !isPharmAdmin && "hidden"
              } px-3 text-xs text-gray-500 uppercase `}
            >
              Medicine stock
            </label>

            {isPharmAdmin && (
              <Link
                to={"/pharmacy/add"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#000000"
                  fill="none"
                  className="object-cover w-8 h-8 rounded-full"
                >
                  <path
                    d="M11 15C6.58172 15 3 13.6569 3 12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M19 5V11.5M3 5V19C3 20.6569 6.58172 22 11 22C11.1679 22 11.3346 21.9981 11.5 21.9942"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <ellipse
                    cx="11"
                    cy="5"
                    rx="8"
                    ry="3"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M7 8V10"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7 15V17"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M17 16.6667V18M17 18V19.3333M17 18H18.3333M17 18H15.6667M21 18C21 20.2091 19.2091 22 17 22C14.7909 22 13 20.2091 13 18C13 15.7909 14.7909 14 17 14C19.2091 14 21 15.7909 21 18Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Add Medicine Stock
                  </h1>
                </div>
              </Link>
            )}
            {isPharmAdmin && (
              <Link
                to={"/pharmacy"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#000000"
                  fill="none"
                  className="object-cover w-8 h-8 rounded-full"
                >
                  <path
                    d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 14.5H3.03875C3.51106 14.5 3.74721 14.5 3.95256 14.4013C4.15791 14.3026 4.30544 14.1182 4.60049 13.7494L6 12L7.5 14.5L9 11L11.5 16L15 9L17 12.5L18.5 11L19.9453 13.168C20.1973 13.546 20.3233 13.735 20.5074 13.8494C20.544 13.8721 20.582 13.8925 20.6212 13.9103C20.8185 14 21.0457 14 21.5 14"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 2.5V5.5M15 21.5V14.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="15"
                    cy="9"
                    r="1"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    View Medicine Stock
                  </h1>
                </div>
              </Link>
            )}

            <div
              className={`${
                !isPharmAdmin && "hidden"
              } mt-2 border-t border-gray-300 `}
            ></div>
            <label
              className={`${
                !isPharmAdmin && "hidden"
              } px-3 text-xs text-gray-500 uppercase `}
            >
              Orders
            </label>
            {isPharmAdmin && (
              <Link
                to={"/pharmacy/orders"}
                className="flex items-center w-full px-5 py-2 transition-colors duration-200  gap-x-2 hover:bg-gray-100 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#000000"
                  fill="none"
                  className="object-cover w-8 h-8 rounded-full"
                >
                  <path
                    d="M3 4.5C3 3.67157 3.67157 3 4.5 3H6.5C7.32843 3 8 3.67157 8 4.5V6.5C8 7.32843 7.32843 8 6.5 8H4.5C3.67157 8 3 7.32843 3 6.5V4.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3 17.5C3 16.6716 3.67157 16 4.5 16H6.5C7.32843 16 8 16.6716 8 17.5V19.5C8 20.3284 7.32843 21 6.5 21H4.5C3.67157 21 3 20.3284 3 19.5V17.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.00002 18.5H21M16 5.5H8.00002M16.3235 7.67649L7.64868 16.3513"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 4.5C16 3.67157 16.6716 3 17.5 3H19.5C20.3284 3 21 3.67157 21 4.5V6.5C21 7.32843 20.3284 8 19.5 8H17.5C16.6716 8 16 7.32843 16 6.5V4.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M18 21L19.3883 20.0537C20.4628 19.3213 21 18.9551 21 18.5C21 18.0449 20.4628 17.6787 19.3883 16.9463L18 16"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="text-left rtl:text-right">
                  <h1 className="text-sm font-medium text-gray-700 capitalize ">
                    Medicine Orders
                  </h1>
                </div>
              </Link>
            )}
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Nav;

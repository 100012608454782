// userHospitalSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";

export const fetchUserHospital = createAsyncThunk(
  "userHospital/fetchUserHospital",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { user } = getState().auth;
      const response = await axios.get(`${API_BASE_URL}user/hospital`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch hospital data"
      );
    }
  }
);

const userHospitalSlice = createSlice({
  name: "userHospital",
  initialState: {
    userHospital: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserHospital.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userHospital = action.payload;
      })
      .addCase(fetchUserHospital.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default userHospitalSlice.reducer;

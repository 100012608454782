import { useSelector } from "react-redux";

export const useHasUserRole = (roleName) => {
  const { roles } = useSelector((state) => state.roles);
  const { user } = useSelector((state) => state.auth.user);

  if (!user || !user.role_id || !roles || roles.length === 0) {
    return false;
  }

  const role = roles.find((r) => r.id === user.role_id);
  return role ? role.name === roleName : false;
};

import React from "react";
import StatisticCard from "./StatisticCard";
import ChartComponent from "./admin/ChartComponent";
import { FaHospital, FaUserMd, FaTasks, FaUsers } from "react-icons/fa";
import { useSelector } from "react-redux";

const DashboardAdmin = ({ stats }) => {
  const inquiriesByStatus = stats.inquiries_by_status || [];
  const monthlyInquiries = stats.monthly_inquiries || [];

  return (
    <div className="p-8 grid grid-cols-1 gap-6">
      <h1 className="text-3xl font-bold text-center mb-4">Dashboard</h1>

      {/* Top Row - Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatisticCard
          title="Total Active Hospitals"
          value={stats.total_active_hospitals}
          icon={<FaHospital />}
          bgColor="bg-gradient-to-r from-blue-400 to-blue-600"
        />
        <StatisticCard
          title="Total Active Hospital Admins"
          value={stats.total_active_hospital_admins}
          icon={<FaUsers />}
          bgColor="bg-gradient-to-r from-purple-400 to-purple-600"
        />
        <StatisticCard
          title="Total Active Doctors"
          value={stats.total_active_doctors}
          icon={<FaUserMd />}
          bgColor="bg-gradient-to-r from-yellow-400 to-yellow-600"
        />
        <StatisticCard
          title="Total Patient Inquiries"
          value={stats.total_patient_inquiries}
          icon={<FaTasks />}
          bgColor="bg-gradient-to-r from-red-400 to-red-600"
        />
        <StatisticCard
          title="Total Completed Inquiries"
          value={stats.total_completed_patient_inquiries}
          icon={<FaTasks />}
          bgColor="bg-gradient-to-r from-green-400 to-green-600"
        />
        <StatisticCard
          title="Hospitals with Active Inquiries"
          value={stats.hospitals_with_active_inquiries}
          icon={<FaHospital />}
          bgColor="bg-gradient-to-r from-indigo-400 to-indigo-600"
        />
      </div>

      {/* Bottom Row - Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Chart for inquiries by status */}
        <div className="bg-white shadow-lg rounded-lg p-6 h-fit">
          <span>Patient Inquiries</span>
          {inquiriesByStatus.length > 0 ? (
            <ChartComponent data={inquiriesByStatus} type="pie" />
          ) : (
            <p className="text-center text-gray-500">
              No data available for inquiries by status.
            </p>
          )}
        </div>

        {/* Chart for monthly inquiries */}
        <div className="bg-white shadow-lg rounded-lg p-6 h-fit">
          <span>No Of Patient inquiries Per month</span>
          {monthlyInquiries.length > 0 ? (
            <ChartComponent
              data={monthlyInquiries.map((m) => ({
                status_name: m.month_name,
                total: m.total,
              }))}
              type="bar"
            />
          ) : (
            <p className="text-center text-gray-500">
              No data available for monthly inquiries.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;

import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const ChartComponent = ({ data = [], type }) => {
  const chartData = {
    labels: data.map((d) => d.ageGroup || d.status),
    datasets: [
      {
        label: "Age ranges",
        data: data.map((d) => d.total),
        backgroundColor:
          type === "pie"
            ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"]
            : [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF9F40",
                "#FF8C00",
                "#20B2AA",
                "#8A2BE2",
                "#A52A2A",
              ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 20,
          boxWidth: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            label += context.raw;
            return label;
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-64 sm:h-80 lg:h-96">
      {type === "pie" && <Pie data={chartData} options={options} />}
      {type === "bar" && <Bar data={chartData} options={options} />}
    </div>
  );
};

export default ChartComponent;

import React, { useState } from "react";

const InquiryForm = () => {
  // Define the initial form state
  const [formData, setFormData] = useState({
    hospital_id: 1,
    patient_id: 1,
    specialization_id: 4,
    inquiry_description: "Looking for a second opinion on recent surgery.",
  });

  // Handle input change to update the form state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "http://127.0.0.1:8000/api/patients/inquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      console.log("Inquiry submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting the inquiry:", error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-lg"
    >
      <h2 className="text-2xl font-bold mb-6">Hospital Inquiry Form</h2>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Hospital ID:
        </label>
        <input
          type="number"
          name="hospital_id"
          value={formData.hospital_id}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Patient ID:
        </label>
        <input
          type="number"
          name="patient_id"
          value={formData.patient_id}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Specialization ID:
        </label>
        <input
          type="number"
          name="specialization_id"
          value={formData.specialization_id}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Inquiry Description:
        </label>
        <textarea
          name="inquiry_description"
          value={formData.inquiry_description}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
      >
        Submit Inquiry
      </button>
    </form>
  );
};

export default InquiryForm;

// src/components/Dashboard.js
import React from "react";
import StatisticCard from "./StatisticCard";
import ChartComponent from "./hospAdmin/ChartComponent";
import { FaRegListAlt, FaUserMd } from "react-icons/fa";

const Dashboard = ({ data = {} }) => {
  const {
    total_active_doctors = 0,
    total_patient_inquiries = 0,
    inquiry_statuses = {},
    doctor_performance = [],
    patient_demographics = [],
  } = data;

  const inquiryStatuses = Object.keys(inquiry_statuses).map((status) => ({
    status_name: status.replace(/_/g, " ").toUpperCase(),
    total: inquiry_statuses[status],
  }));

  const doctorPerformanceData = doctor_performance.map((performance) => ({
    name: performance.doctor.name,
    email: performance.doctor.email,
    total_inquiries: performance.total_inquiries,
    submitted_for_review_inquiries: performance.submitted_for_review_inquiries,
    doctor_responding_inquiries: performance.doctor_responding_inquiries,
    completed_inquiries: performance.completed_inquiries,
  }));


  const patientDemographics = patient_demographics.map((demographic) => ({
    age: demographic.age,
    total: demographic.total,
  }));

  return (
    <div>
      <h1 className="text-3xl font-bold text-center mb-8">Dashboard</h1>

      <div className="p-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Stats Cards */}
        <div className="grid grid-cols-1 gap-4">
          <StatisticCard
            title="Total Active Doctors"
            value={total_active_doctors}
            icon={<FaUserMd />}
            bgColor="bg-gradient-to-r from-blue-300 via-blue-400 to-blue-600"
          />
          <StatisticCard
            title="Total Patient Inquiries"
            value={total_patient_inquiries}
            icon={<FaRegListAlt />}
            bgColor="bg-gradient-to-r from-green-300 via-green-400 to-green-600"
          />
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 gap-6">
          <div className="bg-white shadow-lg rounded-lg p-6 h-fit">
            <span className="text-xl font-bold">Patient Inquiry Status</span>
            {inquiryStatuses.length > 0 ? (
              <ChartComponent data={inquiryStatuses} type="pie" />
            ) : (
              <p className="text-center text-gray-500">
                No data available for inquiry statuses.
              </p>
            )}
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 h-fit">
            <h2 className="text-xl font-bold mb-4">Patient Demographics</h2>
            {patientDemographics.length > 0 ? (
              <ChartComponent data={patientDemographics} type="bar" />
            ) : (
              <p className="text-center text-gray-500">
                No data available for patient demographics.
              </p>
            )}
          </div>
        </div>

        <div className="col-span-1 lg:col-span-2 bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-xl font-bold mb-4">Doctor Performance</h2>
          {doctorPerformanceData.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                <thead className="bg-gradient-to-r from-blue-300 via-sky-300 to-indigo-300 text-white sticky top-0">
                  <tr>
                    <th className="py-3 px-4 text-left font-semibold">Name</th>
                    <th className="py-3 px-4 text-left font-semibold">Email</th>
                    <th className="py-3 px-4 text-left font-semibold">
                      Total Inquiries
                    </th>
                    <th className="py-3 px-4 text-left font-semibold">
                      Inquiries Assigned
                    </th>
                    <th className="py-3 px-4 text-left font-semibold">
                      Inquiries Accepted
                    </th>
                    <th className="py-3 px-4 text-left font-semibold">
                      Inquiries Completed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {doctorPerformanceData.map((doctor, index) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      } hover:bg-indigo-50 transition duration-200 ease-in-out`}
                    >
                      <td className="py-3 px-4 text-gray-700 font-medium">
                        {doctor.name}
                      </td>
                      <td className="py-3 px-4 text-gray-700">
                        {doctor.email}
                      </td>
                      <td className="py-3 px-4 text-gray-800">
                        {doctor.total_inquiries}
                      </td>
                      <td className="py-3 px-4 text-gray-800">
                        {doctor.submitted_for_review_inquiries}
                      </td>
                      <td className="py-3 px-4 text-gray-800">
                        {doctor.doctor_responding_inquiries}
                      </td>
                      <td className="py-3 px-4 text-gray-800">
                        {doctor.completed_inquiries}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-gray-500">
              No data available for doctor performance.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

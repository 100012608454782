import React from "react";
import { Form, Input, Button, Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";

const { Option } = Select;

function UpdateAdminDetails({ admin, onClose, setRefresh, refresh }) {
  const [form] = Form.useForm();

  /**
   * @function handleSubmit
   * @param {*} values 
   */
  const handleSubmit = (values) => {
    // Handle form submission for updating admin details;
    const onSuccess =(response)=>{
      toast.success("Update Successful ", {
        theme: "colored",
      });
      setRefresh(!refresh)
      onClose(); // Close modal on successful update
    }
    const onError = () =>{
        toast.error("Error occured while Updating", {
            theme:"colored"
        })
    }
    apiRequest("put", `${API_BASE_URL}admin/hospital-admins/${admin.id}`, values, "", onSuccess, onError);
  };

  return (
    <>
      <Form
        form={form}
        initialValues={admin}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please enter the name!" },
            { max: 255, message: "Name must be at most 255 characters!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter the email!" },
            { type: "email", message: "The input is not valid E-mail!" },
            { max: 255, message: "Email must be at most 255 characters!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
          <Button onClick={onClose} style={{ marginLeft: "8px" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
      <ToastContainer autoClose={5000} />
    </>
  );
}

export default UpdateAdminDetails;

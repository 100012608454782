import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";


function RegisterPharmacyAdmin() {
  const [pharmacies, setPharmacies] = useState([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contacts: [""], // Array of contacts
    password: "",
    password_confirmation: "",
  });

  // State for errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle contact change
  const handleContactChange = (index, value) => {
    const updatedContacts = [...formData.contacts];
    updatedContacts[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      contacts: updatedContacts,
    }));
  };

  const fetchPharmacies = () => {
    const onSuccess = (response) => {
      setPharmacies(response);
    //   toast.success("Pharmacies retrieved successfully");
    };
    const onError = () => {
      toast.error("Failed to retrieve pharmacies records");
    };
    apiRequest(
      "get",
      `${API_BASE_URL}pharmacies`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({});

    // Validation logic
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!selectedPharmacy) newErrors.pharmacy = "Pharmacy is required";
    if (!formData.contacts.length || !formData.contacts[0])
      newErrors.contacts = "At least one contact is required";
    if (formData.password !== formData.password_confirmation)
      newErrors.password_confirmation = "Passwords do not match";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const onSuccess = (response) => {
      toast.success("Pharmacy Admin record Saved", {
        theme: "colored",
      });
      setFormData({
        name: "",
        email: "",
        contacts: [""],
        password: "",
        password_confirmation: "",
      });
      setSelectedPharmacy(null);
    };

    const onError = (error) => {
      toast.error(
        error.response.data.email
          ? error.response.data.email[0]
          : "An error occurred",
        {
          theme: "colored",
        }
      );
    };
    
    apiRequest(
      "post",
      `${API_BASE_URL}pharmacy-admin/${selectedPharmacy}`,
      formData,
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    fetchPharmacies();
  }, []);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Register New Pharmacy Admin
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4">
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="pharmacy"
                >
                  Select Pharmacy
                </label>
                <select
                  id="pharmacy"
                  name="pharmacy"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={selectedPharmacy}
                  onChange={(e) => setSelectedPharmacy(e.target.value)}
                >
                  <option value="">Select a pharmacy</option>
                  {pharmacies.map((pharmacy) => (
                    <option key={pharmacy.id} value={pharmacy.id}>
                      {pharmacy.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="contacts"
                >
                  Phone Contacts
                </label>
                {formData.contacts.map((contact, index) => (
                  <input
                    key={index}
                    id={`contact_${index}`}
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                    value={contact}
                    onChange={(e) => handleContactChange(index, e.target.value)}
                  />
                ))}
                {errors.contacts && (
                  <p className="text-red-500 text-sm">{errors.contacts}</p>
                )}
              </div>
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="password_confirmation"
                >
                  Password Confirmation
                </label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
                {errors.password_confirmation && (
                  <p className="text-red-500 text-sm">
                    {errors.password_confirmation}{" "}
                  </p>
                )}{" "}
              </div>{" "}
            </div>{" "}
            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-6 py-2 leading-5 text-white bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Save Admin Record
              </button>
            </div>
          </form>
        </section>
        <ToastContainer />
      </div>
    </div>
  );
}

export default RegisterPharmacyAdmin;

import React, {useState} from "react";
import StatisticCard from "./pharmacy/StatisticCard";
import {
  FaPills,
  FaShoppingCart,
  FaDollarSign,
  FaMoneyBillWave,
  FaCapsules,
} from "react-icons/fa";
import UpdateDetails from "../pharmacy/UpdateDetails";

const dayOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

const PharmacyDashboard = ({ data, onDayOfWeekChange }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const lowStockMedicines = data.low_stock_medicines || [];
  const topSellingMedicines = data.top_selling_medicines || [];

  const showModal = (drug) => {
    setSelectedDrug(drug);
    setUpdateModal(true);
  };
  const handleCloseModal = () => {
    setUpdateModal(false);
    setSelectedDrug(null);
  };

   const handleDayChange = (event) => {
     const day = parseInt(event.target.value, 10);
     setSelectedDay(day);
     onDayOfWeekChange(day); // Notify parent about the day change
   };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Pharmacy Dashboard</h1>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Filter by Day:
        </label>
        <select
          value={selectedDay || ""}
          onChange={handleDayChange}
          className="form-select mt-1 block w-full bg-white border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="">Select a day</option>
          {dayOptions.map((day) => (
            <option key={day.value} value={day.value}>
              {day.label}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatisticCard
          title="Total Medicines"
          value={data.total_medicines}
          icon={<FaPills />}
          bgColor="from-green-400 to-blue-500"
        />
        <StatisticCard
          title="Total Orders"
          value={data.total_orders}
          icon={<FaShoppingCart />}
          bgColor="from-yellow-400 to-red-500"
        />
        <StatisticCard
          title="Total Sales"
          value={data.total_sales}
          icon={<FaDollarSign />}
          bgColor="from-purple-400 to-pink-500"
        />
        <StatisticCard
          title="Total Revenue"
          value={`UGX ${parseInt(data.total_revenue).toLocaleString()}`}
          icon={<FaMoneyBillWave />}
          bgColor="from-indigo-400 to-purple-500"
        />
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Low Stock Medicines</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {lowStockMedicines.length > 0 ? (
            lowStockMedicines.map((medicine) => (
              <div
                key={medicine.id}
                className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-semibold">{medicine.name}</h3>
                  <FaCapsules className="text-3xl text-blue-500" />
                </div>
                <p className="text-gray-700 mb-2">
                  <strong>Stock:</strong> {medicine.stock}
                </p>
                <p className="text-gray-700 mb-2">
                  <strong>Dosage Form:</strong> {medicine.dosage_form}
                </p>
                <p className="text-gray-700 mb-2">
                  <strong>Manufacturer:</strong> {medicine.manufacturer}
                </p>
                <div className="mt-4">
                  <button
                    onClick={() => showModal(medicine)}
                    className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600"
                  >
                    Update Stock
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No medicines are low in stock.</p>
          )}
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Top Selling Medicines</h2>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold text-gray-800 mb-4">
            Top-Selling Medicines
          </h3>
          {topSellingMedicines.length > 0 ? (
            <ul className="space-y-4">
              {topSellingMedicines.map((medicine) => (
                <li
                  key={medicine.medicine_id}
                  className="flex justify-between items-center border-b border-gray-200 pb-2"
                >
                  <div>
                    <p className="text-md font-semibold text-gray-900">
                      {medicine.medicine_name}
                    </p>
                    <p className="text-sm text-gray-600">
                      Total Quantity Sold:{" "}
                      <span className="font-medium text-gray-800">
                        {medicine.total_quantity}
                      </span>
                    </p>
                  </div>
                  <div className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-xs font-semibold">
                    Best Seller
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No top-selling medicines available.</p>
          )}
        </div>
      </div>

      <UpdateDetails
        visible={updateModal}
        drug={selectedDrug}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default PharmacyDashboard;

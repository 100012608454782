import React, { useEffect, useState } from "react";
import Nav from "../../nav";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";

function RegisterHospitalAdmin() {
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: [""],
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle contact change
  const handleContactChange = (index, value) => {
    const updatedContacts = [...formData.contact];
    updatedContacts[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      contact: updatedContacts,
    }));
  };

  const addContactField = () => {
    setFormData((prevData) => ({
      ...prevData,
      contact: [...prevData.contact, ""],
    }));
  };

  const fetchHospitals = () => {
    const onSuccess = (response) => {
      setHospitals(response.data);
    };
    const onError = () => {
      toast.error("Failed to retrieve hospital records");
    };
    apiRequest(
      "get",
      `${API_BASE_URL}admin/hospitals`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";

    // Check if all contacts are valid and at least 10 characters long
    formData.contact.forEach((contact, index) => {
      if (!contact || contact.length < 10) {
        newErrors.contact = `Contact at position ${
          index + 1
        } must be valid and at least 10 characters long.`;
      }
    });

    if (formData.password !== formData.password_confirmation)
      newErrors.password_confirmation = "Passwords do not match";
    if (!selectedHospital) newErrors.hospital = "Hospital is required";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const onSuccess = (response) => {
      toast.success("Hospital Admin record saved", { theme: "colored" });
      setFormData({
        name: "",
        email: "",
        contact: [""],
        password: "",
        password_confirmation: "",
      });
      setSelectedHospital(null);
    };

    const onError = (error) => {
      const errorMessage =
        error.response?.data?.email?.[0] ||
        "An error occurred. Please try again.";
      toast.error(errorMessage, { theme: "colored" });
    };

    apiRequest(
      "post",
      `${API_BASE_URL}admin/hospitals/${selectedHospital}/admins`,
     formData,
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  return (
    <div className="flex h-screen">
      <Nav />
      <div className="flex-1 p-8 bg-gray-100 ">
        <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md ">
          <h2 className="text-lg font-semibold text-gray-700 capitalize ">
            Register New Hospital Admin
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4">
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="hospital"
                >
                  Select Hospital
                </label>
                <select
                  id="hospital"
                  name="hospital"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={selectedHospital}
                  onChange={(e) => setSelectedHospital(e.target.value)}
                >
                  <option value="">Select a hospital</option>
                  {hospitals.map((hospital) => (
                    <option key={hospital.id} value={hospital.id}>
                      {hospital.hosp_name}
                    </option>
                  ))}
                </select>
                {errors.hospital && (
                  <p className="text-red-500 text-sm">{errors.hospital}</p>
                )}
              </div>

              {/* Name, Email, Contacts, Passwords */}
              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="contact"
                >
                  Phone Contact
                </label>
                {formData.contact.map((contact, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      name="contact"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                      value={contact}
                      onChange={(e) =>
                        handleContactChange(index, e.target.value)
                      }
                    />
                    {index === formData.contact.length - 1 && (
                      <button
                        type="button"
                        className="px-4 py-2 text-white bg-green-500 rounded-md"
                        onClick={addContactField}
                      >
                        Add
                      </button>
                    )}
                  </div>
                ))}
                {errors.contact && (
                  <p className="text-red-500 text-sm">{errors.contact}</p>
                )}
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              <div>
                <label
                  className="text-gray-700 "
                  htmlFor="password_confirmation"
                >
                  Password Confirmation
                </label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
                {errors.password_confirmation && (
                  <p className="text-red-500 text-sm">
                    {errors.password_confirmation}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
              >
                Register
              </button>
            </div>
          </form>
        </section>
      </div>
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default RegisterHospitalAdmin;

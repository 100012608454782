// App.js
import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import Hospitals from "./components/admin/hospital";
import RegisterHospital from "./components/admin/hospital/RegisterHospital";
import ViewHospitalAdmin from "./components/admin/hospital/ViewHospitalAdmin";
import RegisterHospitalAdmin from "./components/admin/hospital/RegisterHospitalAdmin";
import RegisterDoctor from "./components/hospAdmin/RegisterDoctor";
import ViewDoctors from "./components/doctor/ViewDoctors";
import ViewPatientInquiries from "./components/hospAdmin/ViewPatientInquiries";
import DoctorPatientInquiries from "./components/doctor/DoctorPatientInquiries";
import ViewMedicineOrders from "./components/pharmacy/ViewMedicineOrders";
import RegisterMedicineStock from "./components/pharmacy/RegisterMedicineStock";
import ViewMedicineStock from "./components/pharmacy/ViewMedicineStock";
import RegisterPharmacy from "./components/pharmacy/RegisterPharmacy";
import ViewPharmacy from "./components/pharmacy/ViewPharmacy";
import PasswordReset from "./components/auth/PassowordReset";
import RegisterPharmacyAdmin from "./components/pharmacy/RegisterPharmacyAdmin";
import ViewPharmacyAdmins from "./components/pharmacy/ViewPharmacyAdmins";
import MedicalForm from "./components/MedicalForm";
import InquiryForm from "./components/PatientInquiry";
import PharmacyForm from "./components/Order";
import Analytics from "./components"


function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/hospitals" component={Hospitals} />
      <Route path="/hospital/register" component={RegisterHospital} />
      <Route path="/hosp/admins" component={ViewHospitalAdmin} />
      <Route path="/register/hosp/admins" component={RegisterHospitalAdmin} />
      <Route path="/register/doctors" component={RegisterDoctor} />
      <Route path="/view/doctors" component={ViewDoctors} />
      <Route path="/patient/inquires" component={ViewPatientInquiries} />
      <Route path="/doctor/inquiries" component={DoctorPatientInquiries} />
      <Route path="/analytics" component={Analytics} />
      <Route path="/pharmacy/orders" component={ViewMedicineOrders} />
      <Route path="/pharmacy/add" component={RegisterMedicineStock} />
      <Route path="/pharmacy" component={ViewMedicineStock} />
      <Route path="/pharmacies/register" component={RegisterPharmacy} />
      <Route path="/pharmacies/view" component={ViewPharmacy} />
      <Route path="/passwordReset" component={PasswordReset} />
      <Route
        path="/pharmacies/register/admins"
        component={RegisterPharmacyAdmin}
      />
      <Route path="/pharmacies/view/admins" component={ViewPharmacyAdmins} />
      <Route path="/patient" component={MedicalForm} />
      <Route path="/inquiry" component={InquiryForm} />
      <Route path="/order" component={PharmacyForm} />
    </Switch>
  );
}

export default App;
